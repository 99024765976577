/**
 * @module {constantRouterMap} 需要默认挂载的路由表
 * @module {asyncRouterMap} 同 server permission list 做匹配，具备权限的动态挂载到路由表
 */
import { asyncRouterMap, constantRouterMap } from '@/router'

/**
 * @param {sidebarRouters} Dom 构建界面左侧菜单栏
 * @param {resources} Api 按钮权限
 *
 * @generator {sidebarRouters} GenerateRoutes => SET_SIDEBAR_ROUTERS
 * @generator {resources} SaveResourcePermission => SET_PERMISSION_RESOURCE
 */
const state = {
  sidebarRouters: [],
  resources: []
}

const mutations = {
  /** @returns {SET_SIDEBAR_ROUTERS} 返回 sidebar */
  SET_SIDEBAR_ROUTERS: (state, routers) => {
    state.sidebarRouters = constantRouterMap.concat(routers)
  },
  /** @returns {SET_PERMISSION_RESOURCE} 返回Api权限表 */
  SET_PERMISSION_RESOURCE: (state, resources) => {
    state.resources = resources
  }
}

const actions = {
  /**
   * @function GenerateRoutes 生成 sidebar + vue router
   * @function SaveResourcePermission 生成Api权限表
   */
  GenerateRoutes({ commit }, date) {
    // "data" here is permission_resource from the server
    return new Promise(resolve => {
      const permission_router = date.permission_array.filter(item => item.type === 1)
      const roles_routers = asyncRouterMap
      const parNavRple = []
      // 获取拥有权限的菜单(顶级权限菜单)
      roles_routers.forEach((route, index) => {
        if (route.hasOwnProperty('meta') && route.meta.hasOwnProperty('requireAPI')) {
          const obj = {}
          obj[index] = route.meta.requireAPI
          parNavRple.push(obj)
        }
        // console.log("parNavRple", parNavRple); // []
      })
      // 1. 权限菜单第一次过滤(顶级菜单权限未拥有则从权限菜单中移除)
      parNavRple.length >= 1 && parNavRple.forEach(item => {
        for (const key in item) {
          const judgeCurRouteRole = permission_router.some(role => (item[key].method === role.method && item[key].url === role.url))
          if (!judgeCurRouteRole) {
            roles_routers.splice(key, 1)
          }
        }
      })
      // 2. 权限菜单第二次过滤(子权限未拥有则从权限菜单中移除)
      roles_routers.forEach((route, index) => {
        if (!(route.children && route.children.length >= 1)) {
          // 此处用于处理父级路由 | 父级菜单 (留待以后有需求使用)
        } else { // 处理子路由 | 子菜单
          if (parNavRple.length > 1) {
            // action
          }
          // 此处对没有路由权限的 router 进行 去除
          roles_routers[index].children = route.children.filter(role => {
            let flag = false
            permission_router.forEach(per => {
              if (per.method === role.meta.requireAPI.method && per.url === role.meta.requireAPI.url) {
                flag = true
              }
            })
            return flag
          })
        }
      })
      commit('SET_SIDEBAR_ROUTERS', roles_routers)
      resolve(roles_routers)
    })
  },
  SaveResourcePermission({ commit }, data) {
    // "data" here is permission_resource from the server
    return new Promise(resolve => {
      const permission_resource = data.permission_array.filter(
        per => per.type === 2
      )
      permission_resource.forEach(per => {
        per.method = per.method.toLowerCase()
        per.url = per.url.replace('/cloud/', '')
      })
      // console.log("permission_resource", permission_resource);
      commit('SET_PERMISSION_RESOURCE', permission_resource)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
