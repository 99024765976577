/*
 * @Description:
 * @Author: warren
 * @LastEditors: Warren
 * @Date: 2023-04-12 09:55:58
 * @LastEditTime: 2024-05-13 09:46:14
 */
import Vue from 'vue'
import dateTime from '@/utils/dateTime'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'
import * as filters from './utils/filters' // global filters

import '@/icons' // icon
import '@/permission' // permission control

// 权限api
import { apiPermission } from '@/mixins/permission.js' // global use api permission

// i18n
import i18n from './lang' // global api permissions
Vue.mixin(apiPermission) // Internationalization

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

/* 统一处理时间 */
Vue.prototype.$date = dateTime

// register global utility filters.
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

// set Element lang to EN
Vue.use(Element, {
    size: 'medium', // set element-ui default size
    i18n: (key, value) => i18n.t(key, value) // 在注册Element时设置i18n的处理方法  可实现vue-i18n和ElementUI的完美结合
})
// 如果想要中文版 element-ui，按如下方式声明
Vue.use(Element)

Vue.config.productionTip = false

new Vue({
    el: '#app',
    router,
    store,
    i18n,
    render: h => h(App)
})
