/**
 * @Author: fuyonghong
 * @Date: 2023-04-10 11:58:13
 * @LastEditTime: 2023-08-30 10:46:13
 * @LastEditors: fuyonghong
 * @Description: var中文翻译
 */
export default {
    var: {
        permanent: '永久',
        chooseOrganization: '选择组织',
        searchPlaceholders: '请输入组织名称或地址',
        organizationHasAdded: '该组织已添加',
        editInfo: '修改信息',
        editAdmin: '编辑管理员',
        orgName: '组织名称',
        orgAddress: '组织地址',
        totalDevices: '设备总数',
        totalMembers: '成员总数',
        orgAdmin: '组织管理员',
        name: '名称',
        location: '位置',
        coordinate: '坐标',
        addOrg: '添加组织',
        setAdmin: '设置管理员',
        editOrg: '修改组织信息',
        manageAdmin: '管理员管理',
        unAccepted: '未接受',
        unRegistered: '未注册',
        varRoles: 'VAR角色',
        orgRoles: '组织角色',
        deleteOrg: '删除组织',
        deleteOrgTips: '请输入当前组织完整名称以确认删除',
        deleteNameError: '组织名称不匹配',
        reinviteOneTips: '将向该用户重新发送邀请邮件或短信',
        reinviteOneDone: '已经向该用户重新发送邀请邮件或短信',
        repeatInviteError: '该用户已经是以下组织的管理员，不能重复邀请',
        region: '区域',
        global: '亚太',
        europe: '欧洲',
        america: '美国',
        cn: '国内',
        createError: '不能在Var组织下创建子级'
    }
}
