/*
 * @Description:
 * @Author: warren
 * @LastEditors: Warren
 * @Date: 2023-10-24 09:14:48
 * @LastEditTime: 2024-07-04 09:04:06
 */
import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import requestConfirm from '@/utils/request-confirm'
import { REGION_URL } from '@/utils/region'
import { getEncryptionHex } from '@/utils/encryption.js'
import { showErrorMessage, initSecurityPage } from '@/api/requestError'
// 默认使用http(), 前缀为/cloud
// 调试可使用其他前缀，例如cloudHttp = http('/cloud-log')，前缀变为/cloud-log/cloud,, 调用时cloudHttp.service()
function http(baseURL) {
    const service = axios.create({
        baseURL: baseURL ? baseURL + process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API, // url = base url + request url
        // withCredentials: true, // send cookies when cross-domain requests
        timeout: 30000 // request timeout
    })

    // 白名单用于不从服务器获得权限的情况
    const whiteList = [
        { method: 'post', url: '/login' },
        { method: 'post', url: '/logout' },
        { method: 'get', url: '/user/permission' },
        // model
        { method: 'get', url: '/smodel' },
        { method: 'post', url: '/smodel' },
        { method: 'put', url: '/smodel' },
        { method: 'delete', url: '/smodel' },
        // model resource
        { method: 'get', url: '/res' },
        { method: 'post', url: '/res' },
        { method: 'put', url: '/res' },
        { method: 'delete', url: '/res' },
        { method: 'delete', url: '/firmware' },
        { method: 'put', url: '/firmware' },
        { method: 'post', url: '/firmware/{id}/release' },

        { method: 'get', url: '/v2/app' },
        { method: 'get', url: '/v2/organization/findByConditionsapp' },
    ]

    // request interceptor
    service.interceptors.request.use(
        config => {
            config.headers.token = getToken()
            // 配置时间戳签名
            config.headers.signature = getEncryptionHex(Date.now().toString())
            /**
             * @describe 统一处理 formData 传参
             * 前提： post 请求
             * 定义接口时设置 formData: true； 配置 data 字段
             * 参考 \src\api\login.js 的 reqLogin 方法
             */
            if (config.formData) {
                const formData = new FormData()
                Object.keys(config.data).forEach(k => {
                    formData.append(k, config.data[k])
                })
                config.data = formData
            }

            const { apiPrefix, API_AREA } = config

            if (apiPrefix) {
                if (API_AREA) {
                    config.baseURL = `${REGION_URL[API_AREA]}${apiPrefix}`
                }
            }

            let flag = false
            let backPartOfRequestUrl = config.url.replace(config.baseURL, '')
            const questionIndex = backPartOfRequestUrl.indexOf('?')
            if (questionIndex !== -1) {
                backPartOfRequestUrl = backPartOfRequestUrl.substring(0, questionIndex)
            }
            const requsetUrlList = backPartOfRequestUrl.split('/')
            const requsetUrl = requsetUrlList.splice(0, requsetUrlList.length - 1).join('/')
            // 前端自定义白名单
            for (const element of whiteList) {
                if (element.method === config.method && (element.url === backPartOfRequestUrl || element.url === requsetUrl)) {
                    flag = true
                }
                continue
            }
            // 不在白名单中
            if (!flag) {
                const permissionResources = store.getters.permission_resources /* 后台权限列表 */

                for (const key in permissionResources) {
                    if (flag) break
                    if (permissionResources[key].method === config.method && permissionResources[key].url === backPartOfRequestUrl) {
                        flag = true
                    }
                }
            }
            // 白名单权限控制
            // if (!flag) {
            //     const error = { message: that.$t('login.noPermission') + backPartOfRequestUrl }
            //     return Promise.reject(error)
            // }

            return config
        },
        error => {
            console.log(error) // for debug
            return Promise.reject(error)
        }
    )

    service.interceptors.response.use(
        response => {
            const res = response.data
            res.code = res.code || 0

            if (res.code === 0) {
                /* code 不等于0 统一进入错误处理， 业务代码需要单独处理异常 可在catch中捕获具体异常code */
                return res
            } else if (res.code === -1002) {
                MessageBox.confirm('Login timeout,Please login again', 'Login Timeout', {
                    confirmButtonText: 'Login Again',
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    closeOnHashChange: false,
                    showCancelButton: false,
                    type: 'warning'
                }).then(() => {
                    store.dispatch('user/resetToken').then(() => {
                        location.reload()
                    })
                })
                return Promise.reject(res)
            } else if (res.code === -1010) {
                requestConfirm({ content: res.msg, title: 'Remote login', confirmButtonText: 'Login Again' }, () => {
                    store.dispatch('user/FedLogOut').then(() => {
                        window.location.href = window.origin
                    })
                })
                return Promise.reject(res)
            } else {
                // TODO 以下code码待后续处理
                if (res.code === -1023) {
                    initSecurityPage(res)
                } else if (![
                    -2010, -2012, -1011,
                    -3005, -7021, -7022 // -7021,-7022 错误单独处理
                ].includes(res.code)) {
                    showErrorMessage(res)
                }
                return Promise.reject(res)
            }
        },
        error => {
            Message({
                message: error.message,
                type: 'error',
                duration: 1500,
                onClose: () => {
                    const token = getToken()
                    // token 过期
                    if (!token) {
                        store.dispatch('user/resetToken').then(() => {
                            location.reload()
                        })
                    }
                }
            })
            return Promise.reject(error)
        }
    )

    /* /cloud-api 公共前缀  目前（20231024）只有一个接口在用 */
    const requestCloudApi = function (data) {
        data.apiPrefix = '/cloud-api'
        return service(data)
    }
    return { service, requestCloudApi }
}
const { service, requestCloudApi } = http()
// 日志调试前缀为/cloud-log/cloud/
export const logService = http('/cloud-log')
export { requestCloudApi }
export default service
