import Cookies from 'js-cookie'

const TokenKey = 'GL-GoodCloud-Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

// https://github.com/js-cookie/js-cookie
export function setToken(token) {
  if (process.env.NODE_ENV === 'production') {
    var rootDomain = window.location.hostname.slice(window.location.hostname.indexOf('.') + 1)
    if (rootDomain === 'goodcloud.xyz') {
      return Cookies.set(TokenKey, token, { domain: '.goodcloud.xyz' })
    }
    return Cookies.set(TokenKey, token)
  } else if (process.env.NODE_ENV === 'development') {
    return Cookies.set(TokenKey, token)
  } else {
    return Cookies.set(TokenKey, token)
  }
}

// IMPORTANT! When deleting a cookie and you're not relying on the default attributes,
// you must pass the exact same path and domain attributes that were used to set the cookie
// https://github.com/js-cookie/js-cookie
export function removeToken() {
  if (process.env.NODE_ENV === 'production') {
    var rootDomain = window.location.hostname.slice(window.location.hostname.indexOf('.') + 1)
    if (rootDomain === 'goodcloud.xyz') {
      return Cookies.remove(TokenKey, { domain: '.goodcloud.xyz' })
    }
    return Cookies.remove(TokenKey)
  } else if (process.env.NODE_ENV === 'development') {
    return Cookies.remove(TokenKey)
  } else {
    return Cookies.remove(TokenKey)
  }
}
