import { MessageBox } from 'element-ui'
/**
 * @description: 请求异常的确认弹窗
 * @param {Object} messageParams 确认弹窗的配置参数
 * @param {Function} callback 弹窗结束后的调用的回调函数
 */
export default function requestErrConfirm (messageParams, callback) {
    const {
        content = '',
        title = '',
        confirmButtonText = '',
        showClose = false,
        closeOnClickModal = false,
        closeOnPressEscape = false,
        closeOnHashChange = false,
        showCancelButton = false,
        type = 'warning',
    } = messageParams
    MessageBox.confirm(content, title, {
        confirmButtonText,
        showClose,
        closeOnClickModal,
        closeOnPressEscape,
        closeOnHashChange,
        showCancelButton,
        type,
    }).then(() => {
        callback()
    })
}
