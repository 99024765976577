/**
 * @Author: fuyonghong
 * @Date: 2023-03-17 11:53:05
 * @LastEditTime: 2023-07-13 11:35:32
 * @LastEditors: fuyonghong
 * @Description:校验翻译--英文
 */
export default {
    validate: {
        required: 'Required',
        email: 'Please enter a valid email address',
        url: 'Please enter a valid URL',
        date: 'Please enter a valid date',
        inputEmptyValidateTip: 'Cannot be empty',
        over_max_length: 'Cannot be longer than {max} characters',
        no_special_characters: 'Cannot contain special characters',
        phoneError: 'Please enter the correct cell phone number',
        orgNameRequired: 'Please enter the organization name',
        infoRequired: 'Information cannot be empty',
        selectApplication: 'Please select Application',
        selectPermission: 'Please select permissions',
        selectType: 'Please select type',
        inputResourceName: 'Please enter the permission group name',
        selectSuperiorResources: 'Please select the superior menu',
        regionRequired: 'Please select region',
        snCode: 'Please enter a valid SN code',
        limitType: 'Please select a valid restriction type',
        limitVersion: 'Please select a valid restricted version',
        limitTime: 'Please select a valid restriction time',
        pushVersion: 'Please select a valid push version',
        pushType: 'Please select a valid push type'
    }
}
