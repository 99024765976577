/**
 * @Author: wangchuan
 * @Date: 2024-05-10 11:54:40
 * @LastEditTime: 2024-07-03 10:25:07
 * @LastEditors: wangchuan
 * @FilePath: \gl-system-frontend\src\lang\logs\en.js
 * @Description: 日志英文翻译
 */
export default {
    logCommon: {
        filter: 'Filter',
        placeholderSelected: 'Please Selected',
        yearMonthDay: 'Year/Month/Day',
        export: 'Export',
        requestParams: 'Request Params',
        responseParams: 'Response Params',
        createTime: 'Time Stamp',
        details: 'Details'
    },
    applicationService: {
        inputSearch: 'Service/Message',
        traceId: 'Trace ID',
        logLevel: 'Log Level',
        flag: 'Flag',
        appName: 'Application & Service',
        msg: 'Message',
        classPath: 'Class Name',
        createTime: 'Time Stamp',
        action: 'Action',
        applicationServiceExport: 'System Log Export',
        stackTrace: 'Stack Trace',
        logFlag: 'Log Flag',
        debug: 'DEBUG',
        info: 'INFO',
        warning: 'WARNING',
        error: 'ERROR',
        webhookPush: 'WebhookPush'
    },
    deviceLog: {
        createTime: 'Create Time',
        inputSearch: 'Device ID/MAC/Bind Information',
        deviceId: 'Device ID',
        mac: 'MAC',
        logType: 'Log type',
        bindInfo: 'Bind Information',
        topic: 'Topic',
        action: 'Action',
        content: 'Content',
        deviceLogExport: 'Device Log Export',
        logFlag: 'Log Flag',
        deviceSign: 'Device Online & Offline',
        deviceMsg: 'Device Msg',
        deviceAuth: 'Device Auth',
        authSuccess: 'Auth Succeeded Status',
        authContent: 'Auth Content',
        response: 'Response',
        msg: 'Message',
        succeed: 'Succeeded',
        failed: 'Failed'
    },
    activityLog: {
        traceId: 'Trace ID',
        inputSearch: 'Username/Email/Operation',
        activity: 'Activity',
        status: 'Activity Status',
        sourceId: 'Activity Portal',
        method: 'Request Method',
        ip: 'IP Address',
        url: 'Access Path',
        duration: 'Request Duration',
        operator: 'Operator',
        operation: 'Operation',
        createTime: 'Time Stamp',
        action: 'Action',
        activityLogExport: 'Activity Log Export',
        requestParams: 'Request Params',
        response: 'Response Params',
        logFlag: 'Log Flag',
        logType: 'Log Type',
        logLevel: 'Log Level',
        email: 'Email',
        phone: 'Phone'
    },
    tracingLog: {
        spanId: 'Span ID',
        inputSearch: 'Trace ID',
        systemComponent: 'System Component',
        applicationService: 'Application & Service',
        service: 'Service',
        viewLogs: 'View Logs'
    }
}
