/*
 * @Description: 
 * @Author: warren
 * @LastEditors: warren
 * @Date: 2023-04-20 18:08:41
 * @LastEditTime: 2023-04-21 14:49:48
 */
export default {
    organization: {
        department: '部门',
        subaccounts: '子账户',
        roles: '角色',
        add: '增加',
        rename: '重命名',
        move: '移动',
        delete: '删除',
        deleteConfirm: '您确定要删除部门"{department}"吗？',
        deleteTips: '此部门内还有子账号和设备，在删除部门之前，您需要将它们迁移到另一个部门内。',
        deleteNote: '注意：迁移数据后，此部门将被删除，此操作不可逆。',
        invite: '邀请',
        modify: '编辑',
        reinvite: '重新邀请',
        remove: '移除',
        userName: '用户名',
        name: '姓名',
        role: '角色',
        email: '邮箱',
        joinTime: '加入时间',
        inviteMember: '邀请成员',
        modifyMember: '编辑成员',
        newMember: '新成员',
        invitePlaceholder: '用户名/邮箱/手机号',
        phoneNumber: '手机号',
        registerEmail: '该用户将收到站内信通知。同时邀请邮件将发送到用户邮箱。',
        registerPhone: '该用户将收到站内信通知。同时邀请短信将发送到用户手机。',
        unregisterEmail: '此邮箱尚未注册，邀请邮件将发送到该邮箱。用户通过邀请邮件注册后将自动加入。',
        unregisterPhone: '此手机号尚未注册，邀请短信将发送到该手机号。用户通过邀请短信注册后将自动加入。',
        access: '访问权限',
        searchPlaceholder: '请输入用户名/姓名/邮箱/手机号',
        includeSub: '包含子部门',
    }
}
