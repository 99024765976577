import { login, logout, getUserPermissionList, getUserPermissionAPI, requestUserInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { Message } from 'element-ui'

const getDefaultState = () => {
    return {
        token: getToken(),
        name: '',
        avatar: '',
        hostList: [],
        userInfo: {}, // 获取到的用户信息
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_HOSTLIST: (state, value) => {
        state.hostList = value
    },
    SET_USER_INFO: (state, data) => {
        state.userInfo = data
    },
}

const actions = {
    // user login
    login({ commit }, userInfo) {
        const { name, password, deviceId } = userInfo
        return new Promise((resolve, reject) => {
            login({ name: name.trim(), password: password, deviceId }).then(response => {
                const { info: token } = response
                setToken(token)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    /* 项目初始化获取 用户信息和var信息 */
    async actionGetUserVar({ commit }) {
        /* 请求用户信息 */
        const { info } = await requestUserInfo()
        commit('SET_USER_INFO', info)
        // 初始化用户名称
        commit('SET_NAME', info?.username)
    },
    // get user info
    GetUserPermission() {
        return new Promise((resolve, reject) => {
            getUserPermissionList().then(response => {
                if (response.code === 0) {
                    // console.log('333')
                } else {
                    Message({
                        message: '获取用户信息失败',
                        type: 'error'
                    })
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // 获取权限api列表
    GetUserPermissionAPIList() {
        return new Promise((resolve, reject) => {
            getUserPermissionAPI().then(response => {
                if (response.code === 0) {
                    // console.log('test test tset')
                } else {
                    Message({
                        message: '权限获取失败',
                        type: 'error'
                    })
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            logout(state.token).then(() => {
                removeToken() // must remove  token  first
                commit('RESET_STATE')
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // 前端 登出
    FedLogOut({ commit }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            removeToken()
            resolve()
        })
    },

    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
