/*
 * @Author: shufei.han
 * @Date: 2024-06-12 12:05:07
 * @LastEditors: shufei.han
 * @LastEditTime: 2024-06-20 18:46:21
 * @FilePath: /gl-system-frontend/src/lang/dataDict/zh.js
 * @Description: 数据字典模块翻译文件
 */
export default {
    dataDict: {
        enabled: '已启用',
        disabled: '已禁用',
        enable: '启用',
        disable: '禁用',
        selectStatusTip: '请选择状态',
        data: '数据',
        startDate: '开始日期',
        endDate: '结束日期',
        searchTip: '请输入字典名称、类型',
        name: '字典名称',
        type: '字典类型',
        status: '状态',
        remark: '备注',
        createTime: '创建时间',
        addDataDict: '添加数据字典',
        editDataDict: '编辑数据字典',
        nameTip: '请输入字典名称',
        typeTip: '请输入字典类型',
        remarkTip: '请输入备注',
        statusTip: '请选择状态',
        disableTip: '确认要禁用{name}吗？',
        label: '数据标签',
        key: '数据键值',
        sort: '显示排序',
        modifySuccess: '修改成功',
        addSuccess: '添加成功',
        searchLabelTip: '请输入数据标签',
        addDataDictData: '添加数据字典数据',
        editDataDictData: '编辑数据字典数据',
        labelTip: ' 请输入字典标签',
        keyTip: '请输入数据键值',
        sortTip: '请输入显示排序',
        deleteTip: '确认删除{name}吗？'
    }
}
