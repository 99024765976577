<template>
    <div class="navbar">
        <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

        <breadcrumb class="breadcrumb-container" />

        <div class="right-menu">
            <lang-select class="international right-menu-item" />
            <el-dropdown class="avatar-container" trigger="click">
                <div class="avatar-wrapper">
                    <i class="el-icon-user-solid" style="font-size:30px"/>
                    <i class="el-icon-caret-bottom" />
                </div>
                <el-dropdown-menu slot="dropdown" class="user-dropdown">
                    <router-link to="/">
                        <el-dropdown-item>
                            {{ $t('navbar.home') }}
                        </el-dropdown-item>
                    </router-link>
                    <a target="_blank" href="https://github.com/PanJiaChen/vue-admin-template/">
                        <el-dropdown-item>{{ $t('navbar.about') }}</el-dropdown-item>
                    </a>
                    <a target="_blank" href="https://panjiachen.github.io/vue-element-admin-site/#/">
                        <el-dropdown-item>Docs</el-dropdown-item>
                    </a>
                    <el-dropdown-item @click.native="showCurrentVersion">{{ $t('navbar.version') }}</el-dropdown-item>
                    <el-dropdown-item divided @click.native="logout">
                        <span style="display:block;">{{ $t('navbar.logOut') }}</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import LangSelect from '@/components/LangSelect'
import { MessageBox } from 'element-ui'
export default {
    name: 'NavBar',
    components: {
        Breadcrumb,
        Hamburger,
        LangSelect
    },
    computed: {
        ...mapGetters([
            'sidebar',
            'avatar'
        ]),
        ...mapState({
            version: state => state.app.version
        })
    },
    methods: {
        showCurrentVersion () {
            MessageBox.confirm(this.$t('navbar.verNum', { verNum: this.version }), this.$t('navbar.version'), {
                confirmButtonText: this.$t('navbar.confirm'),
                closeOnClickModal: false,
                closeOnPressEscape: false,
                closeOnHashChange: false,
                showCancelButton: false,
            })
        },
        toggleSideBar () {
            this.$store.dispatch('app/toggleSideBar')
        },
        async logout () {
            await this.$store.dispatch('user/logout')
            location.reload()
            // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;

    &:focus {
      outline: none;
    }
    .international {
      margin-right: 30px;
      position: relative;
      top: 3px;
    }
    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;
      height: 50px;
      line-height: 50px;

      .avatar-wrapper {
        position: relative;
        top: 5px;
        .el-icon-caret-bottom {
          cursor: pointer;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
