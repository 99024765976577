/**
 * @Author: wangchuan
 * @Date: 2024-05-10 11:54:49
 * @LastEditTime: 2024-07-03 10:24:48
 * @LastEditors: wangchuan
 * @FilePath: \gl-system-frontend\src\lang\logs\zh.js
 * @Description: 日志中文翻译
 */
export default {
    logCommon: {
        filter: '筛选',
        placeholderSelected: '请选择',
        yearMonthDay: '年/月/日',
        export: '导出',
        requestParams: '请求参数',
        responseParams: '响应参数',
        createTime: '创建时间',
        details: '日志详情'
    },
    applicationService: {
        inputSearch: '应用/信息',
        traceId: '链路追踪 ID',
        logLevel: '日志级别',
        flag: '日志标签',
        appName: '应用名称',
        msg: '消息',
        classPath: '类名',
        createTime: '创建时间',
        action: '操作',
        applicationServiceExport: '系统日志',
        stackTrace: '堆栈轨迹',
        logFlag: '日志标签',
        debug: '调试',
        info: '信息',
        warning: '告警',
        error: '错误',
        webhookPush: 'webhookPush'
    },
    deviceLog: {
        createTime: '创建时间',
        inputSearch: '设备ID/MAC/绑定信息',
        deviceId: '设备 ID',
        mac: 'MAC',
        logType: '日志类别',
        bindInfo: '绑定信息',
        topic: '主题',
        action: '操作',
        content: '详细内容',
        logFlag: '日志标签',
        deviceSign: '设备上下线',
        deviceMsg: '设备信息',
        deviceAuth: '设备鉴权',
        deviceLogExport: '设备日志导出',
        authSuccess: '验证状态',
        authContent: '验证内容',
        response: '响应',
        msg: '消息',
        succeed: '成功',
        failed: '失败'
    },
    activityLog: {
        traceId: '链路 ID',
        inputSearch: '用户名/邮箱/操作',
        activity: '操作描述',
        status: '请求状态',
        sourceId: '来源',
        method: '请求类型',
        ip: 'IP 地址',
        url: '访问路径',
        duration: '持续时间',
        operator: '操作人员',
        operation: '操作说明',
        createTime: '创建时间',
        action: '操作',
        requestParams: '请求参数',
        response: '响应参数',
        logFlag: '日志标签',
        activityLogExport: '操作日志导出',
        logType: '日志类型',
        logLevel: '日志级别',
        email: '邮箱',
        phone: '电话'
    },
    tracingLog: {
        spanId: 'Span ID',
        inputSearch: '链路 ID',
        systemComponent: '系统组件',
        applicationService: '应用 & 服务',
        service: '服务列表',
        viewLogs: '查看日志'
    }
}
