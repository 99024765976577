/*
 * @LastEditTime: 2023-11-21 16:18:35
 */

const judgeHostname = () => {
    const hostname = window.location.hostname
    const en = ['www', 'jp', 'us', 'eu', 'dev', 'test'] // 海外
    const zh = ['cloud', 'cloud-test', 'system-dev', 'system'] // 国内
    const isEn = en.find(v => hostname === `${v}.goodcloud.xyz`)
    const isZh = zh.find(v => hostname === `${v}.gl-inet.cn`)
    const isLocalhost = hostname === 'localhost'
    return {
        isEn,
        isZh,
        isLocalhost,
    }
}

/* 判断是否海外服务器 */
const isOverseasArea = function () {
    const { isEn, isZh, isLocalhost } = judgeHostname()
    if (isEn) {
        return true
    } else if (isZh) {
        return false
    } else if (isLocalhost) {
        return false
    } else {
        return true
    }
}

/* 判断是否是云平台 */
const isGLCompany = function () {
    const { isEn, isZh, isLocalhost } = judgeHostname()
    if (isEn || isZh || isLocalhost) {
        return true
    } else {
        return false
    }
}

/* 不同区域调的接口域名 */
const REGION_URL = (function () {
    return {
        /* 本地开发 */
        development: {
            // cn: 'https://cloud-dev.gl-inet.cn',
            cn: '  https://api.lafaer.cn',
        },
        /* 部署开发环境 */
        productionDev: {
            cn: 'https://cloud-dev.gl-inet.cn',
        },
        /* 部署生产环境 */
        productionCn: {
            cn: 'https://cloud.gl-inet.cn',
        },
        production: {
            cn: 'https://cloud.gl-inet.cn',
            jp: 'https://cloud-jp.goodcloud.xyz',
            eu: 'https://cloud-eu.goodcloud.xyz',
            us: 'https://cloud-us.goodcloud.xyz',
        }
    }[process.env.NODE_ENV]
})()

/* 可选区域 */
const firmwareAreaList = (function () {
    return {
        /* 本地开发 */
        development: [
            { value: 'cn', label: 'cn' }
        ],
        /* 部署开发环境 */
        productionDev: [
            { value: 'cn', label: 'cn' }
        ],
        /* 部署国内生产环境 */
        productionCn: [
            { value: 'cn', label: 'cn' }
        ],
        /* 部署生产环境 */
        production: [
            { value: 'jp', label: 'jp' }, { value: 'us', label: 'us' }, { value: 'eu', label: 'eu' }, { value: 'cn', label: 'cn' }
        ],
    }[process.env.NODE_ENV]
})()

export {
    REGION_URL,
    firmwareAreaList,
    isOverseasArea,
    isGLCompany
}
