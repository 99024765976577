export default {
    organization: {
        department: 'Department',
        add: 'Add',
        rename: 'Rename',
        move: 'Move To',
        delete: 'Delete',
        deleteConfirm: 'Are you sure you want to delete department "{department}"?',
        deleteTips: 'The department contains sub-accounts and devices. Before deleting the department, you need to move them to another department.',
        deleteNote: 'Note: The department will be deleted after move data and the operation can\'t be undone.',
        invite: 'Invite',
        modify: 'Modify',
        reinvite: 'Re-invite',
        remove: 'Remove',
        userName: 'Username',
        name: 'Name',
        role: 'Roles',
        email: 'Email',
        joinTime: 'Join Time',
        inviteMember: 'Invite Member',
        modifyMember: 'Modify Member',
        newMember: 'New Member',
        invitePlaceholder: 'Username/Email/Phone Number',
        phoneNumber: 'Phone Number',
        registerEmail: 'The user will be notified by site message. Also the invitation email will be sent to the user\'s mailbox.',
        registerPhone: 'The user will be notified by site message. Also the invitation SMS will be sent to the user\'s phone.',
        unregisterEmail: 'This email address is not yet registered, the invitation email will be sent to the email address. The user will automatically join after registering via the invitation email.',
        unregisterPhone: 'This phone number has not been registered yet, the invitation SMS will be sent to the phone number. The user will automatically join after registering via the invitation SMS.',
        access: 'Access',
        searchPlaceholder: 'Username/Name/Email/Phone Number',
        includeSub: 'Include Sub-Departments',
    }
}
