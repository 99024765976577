/**
 * @Author: wangchuan
 * @Date: 2024-07-04 14:50:05
 * @LastEditTime: 2024-07-16 09:27:09
 * @LastEditors: wangchuan
 * @FilePath: \gl-system-frontend\src\lang\errorCode\zh.js
 * @Description: errorCode 翻译
 */
export default {
    message: {
        sendEmailOrPhoneFailed: '请重新登录确认用户身份。',
        serverError: '服务器错误。',
        i18n_invalidTokenWarning: '登录超时，请重新登录。',
        permissionError: '没有权限访问当前页。',
        tokenExpired: 'Token 失效',
        addDeviceParam: '参数丢失',
        inputParametersError: '输入参数错误',
        accoutLoginInOtherPlace: '账号在其它地方登录。',
        expiredLogin: '登录过期',
        userAlreadyExistInOrg: '此组织中已存在该邀请用户。',
        requestXssError: '请求中的内容存在 xss 注入风险，请检查。',
        securityAuthorizationExpired: '安全授权状态过期，请重新登录。',
        invaliduser2psw: '无效用户名或密码。',
        userunexist: '该用户不存在。',
        confirmPasswordValidateError: '密码不一致。',
        passwordError: '密码错误。',
        userNamealreadyexists: '该用户名已被注册。',
        emailalreadyexists: '该邮箱已被注册！',
        emailCodeError: '邮箱验证码错误。',
        emailerror: '邮箱不正确。',
        emailCodeExpired: '邮箱验证码已过期。',
        Two_factor_code_verification_failed_Please_try_again: '验证码错误，请重试。',
        sendEmailCodeFailed: '发送邮箱验证码失败！',
        phoneCodeExpired: '手机验证码已过期。',
        phoneCodeError: '手机验证码错误。',
        notYourPhone: '当前手机号不是你的。',
        phoneExists: '手机号已存在。',
        phoneNotExist: '手机号不存在。',
        sendCodeTimesError: '验证码发送超过限制条数。',
        regionMatch: '登录区域不匹配',

        VERIFICATION_CODE_COUNT_ERROR: '验证码验证次数超限。',
        DEVICE_LIST_IS_NULL: '设备列表为空。',
        HAS_NOT_TRANSFER_DEVICE: '设备未出库',
        HAS_NOAUTH_DEVICE: '设备未认证',
        ORGID_IS_NULL: '组织ID为空。',
        HAS_NOT_RETURNED_DEVICE: '设备未退回',
        DEVICE_DDNS_MISMATCHING: '设备 DDNS 不匹配',
        ORG_ID_IS_ILLEGAL: '组织ID不合法。',

        unDevices: '未找到设备。',
        devicesOffline: '该设备已下线。',
        devicesTimeout: '设置该设备超时。',
        fileLengthValidateError: '请选择 excel 文件导入。',
        addDeviceExcelError: 'excel内容错误。',
        DEVICE_IMAGE_FORMAT_ERROR: '图片格式错误。',
        DEVICE_IMAGE_SIZE_ERROR: '图片大小错误。',
        DEVICE_FIRMWARE_NOT_FOUND: '未搜索到该设备的固件信息',
        DEVICE_EXECUTION_FAILED: '执行失败',

        one_of_the_devices_had_joined_to_another_site_to_site_network_please_remove_it_then_continue: '有一个设备已经加入到其它站点到站点网络，请移除后继续。',
        infoIncorrect: '您输入的信息(MAC, S/N, DDNS/设备ID)不正确，请检查您输入的信息。',
        DATA_DEPEND: '数据依赖',
        hostAlreadyExists: '',
        TEMPLATE_NAME_EXIST: '模板名称已存在',

        SHARE_ERROR: '无法与自己分享设备',
        RESULT_ERROR: '结果错误',
        gpsNotfound: '未找到GPS设备。',
        error: '错误',
        REPETITION_SHARE_ERROR: '该用户已共享设备',

        GROUP_EXISTS: '组存在。',
        DEVICE_EXISTS: '设备存在。',
        TASK_EXISTS: '任务存在。',
        TEMPLATE_EXISTS: '模板存在。',
        SUBACCOUNT_EXISTS: '子账户存在。',
        ALREADY_SHARED: '设备已共享给该用户。',
        NOT_SUBACCOUNT: '不是子账户。',
        TASK_LIMIT_ILLEGAL_ERR: '任务计数限制错误',
        TASK_TYPE_UNSUPPORTED_ERR: '任务类型不支持错误',
        TASK_REQUEST_EMPTY_ERR: '任务请求清空错误',
        finishAndStopfailure: '任务已经完成，停止失败。',

        FILE_NULL: '无效参数：multipartFile 不能为空。',
        FORMAT_ERROR: '图片格式错误。',
        SIZE_ERROR: '图片大小错误。',
        AUTO_SSL_GOODCLOUD_FAILED: '自动 SSL Goodcloud 失败',
        AUTO_SSL_RTTY_SSH_FAILED: '自动 SSL Rtty SSH 失败',
        AUTO_SSL_RTTY_WEB_FAILED: '自动 SSL Rtty Web 失败',
        USER_CONFIG_EXISTS: '用户配置已存在',
        domainLegal: '提交失败，域名不合法。',
        domainExit: '提交失败，域名已存在。',
        domainError: '域名或ssl证书错误。',
        sslError: '自动ssl证书错误。',
        DNS_RECORD_ERROR: 'Dns 记录错误',
        DOMAIN_MODIFY_ERROR: '此域无法修改域和 SSL 信息',
        RPC_REQUEST_ERROR: 'Rpc 请求错误',
        FAVORITE_COUNT_LIMIT: '收藏夹数量超过上限',
        FAVORITE_NAME_EXISTS: '收藏夹名称已存在',
        guiClosed: 'GUI已关闭。',
        EMAIL_CONFIG_ERROR: '电子邮件配置错误',
        // 以下是新追加
        SERVER_ERROR: '服务器错误',
        INVALID_TOKEN: '无效令牌',
        REQUEST_BUSY_ERROR: '请求太多。稍后再试。',
        PERMISSION_ERROR: '权限错误！',
        DATA_ERROR: '数据错误！',
        TPA_PARAM_ERROR: '参数错误',
        TPA_ORG_EXIST: '组织已经存在。',
        TPA_ORG_NO_EXIST: '该组织不存在。',
        TPA_ILLEGAL_ORG: '非法组织标识',
        TPA_DEVICE_NO_EXIST: '要添加的设备不存在。',
        TPA_EMAIL_ERROR: '非法邮箱。',
        TPA_DEV_NOT_BELONG_ERROR: '不属于本组织的设备',
        SYSTEM_PARENT_ID_NOT_FOUND: '找不到父节点。',
        SYSTEM_PARENT_APPLICATION_MUST_BE_FIRST_LEVEL: '家长申请必须是一级申请。',
        SYSTEM_OBJECT_INFO_EMPTY: '对象信息不能为空！',
        SYSTEM_DATA_TO_BE_MODIFIED_NOT_EXIST: '要修改的数据不存在。请检查！',
        SYSTEM_ID_NOT_NULL: '主键标识应为空。',
        SYSTEM_ID_EMPTY: '主键标识不能为空！',
        SYSTEM_APP_NAME_EMPTY: '应用程序名称不能为空！',
        SYSTEM_APP_CODE_EMPTY: '应用程序代码不能为空！',
        SYSTEM_ILLEGAL_APP_NAME: '应用程序名称非法。',
        SYSTEM_APP_NAME_EXISTS: '应用程序名称存在。',
        SYSTEM_INVALID_TIME_FORMAT: '时间格式无效。',
        SYSTEM_INVALID_ARGUMENT_MULTIPARTFILE_NULL: '无效参数：multipartFile 不能为空。',
        SYSTEM_INVALID_ARGUMENT_OBJECTNAME_NULL: '无效参数：objectName 不能为空或空。',
        SYSTEM_MAC_EMPTY: 'MAC 地址不能为空！',
        SYSTEM_STATUS_EMPTY: '状态（1-成功 /2-失败）不能为空！',
        SYSTEM_INVALID_VERSION_NUMBER: '版本号无效！它只能包含数字、字母、点和连字符。长度应在 1 到 30 之间。',
        USER_SEND_MODE_EMPTY: '发送模式（电子邮件/短信/网站信息）不能为空！',
        USER_RECIPIENT_ID_EMPTY: '收件人标识不能为空！',
        USER_PHONE_EMPTY: '手机不能为空！',
        USER_EMAIL_EMPTY: '电子邮件不能为空！',
        USER_ACCOUNT_EMPTY: '账户不能为空！',
        USER_SELECT_ORG: '请选择一个组织。',
        USER_NOT_EXISTS: '用户不存在。',
        USER_ORG_NOT_EXISTS: '该组织并不存在！',
        USER_CANNOT_DELETE_ADMINISTRATOR: '无法删除管理员！',
        USER_PASSWORD_NOT_MATCH: '用户密码不匹配。',
        USER_ORG_NOT_BELONG_VAR: '该组织不属于 VAR。',
        USER_SELECT_ROLE: '请选择一个角色。',
        ORG_ORGANIZATION_NAME_EXIST: '组织名称已存在。',
        ORG_ILLEGAL_ORG_NAME: '非法组织名称。',
        ORG_CANNOT_FIND_ORG: '找不到组织。',
        SYSTEM_USER_ID_EMPTY: '用户 ID 不能为空！',
        USER_OBJECT_INFO_EMPTY: '对象信息不能为空！',
        USER_ID_NOT_NULL: '主键标识应为空。',
        USER_UPDATE_EMAIL_EMPTY: '电子邮件地址不能为空！',
        USER_UPDATE_USER_TOKEN_EMPTY: '用户令牌不能为空！',
        USER_CANNOT_DELETE_YOURSELF: '无法删除自己！',
        USER_ENTER_MEMBER_ID: '请输入成员 ID。',
        USER_ILLEGAL_MAIL_ADDRESS: '非法邮件地址。',
        USER_INVITATION_EXPIRED: '邀请函已过期。',
        USER_RE_PASSWORD_EMPTY: '重新设置的密码不能为空！',
        USER_CANNOT_FIND_ORG: '找不到组织。',
        ORG_DEPARTMENT_NAME_EXIST: '该部门名称已存在。',
        ORG_PARENT_ORG_NOT_EXIST: '上级组织不存在！',
        ORG_DELETE_DEPARTMENT_ERROR: '此功能只删除部门！',
        ORG_OBJECT_INFO_EMPTY: '对象信息不能为空！',
        ORG_ID_NOT_NULL: '主键标识应为空。',
        ORG_DEPARTMENT_NAME_EMPTY: '部门名称不能为空！',
        ORG_PARENT_ID_EMPTY: '父 ID 不能为空！',
        SYSTEM_SQL_INVALID_PARAMETER: '参数 %s 无效。请检查。',
        SYSTEM_TEMPERATURE_THRESHOLD_RANGE: '温度阈值范围必须在 -10 至 59 之间。',
        USER_DATA_TO_BE_MODIFIED_NOT_EXIST: '要修改的数据不存在。请检查！',
        USER_SEND_STATUS_EMPTY: '发送状态（0-成功 /1-失败）不能为空！',
        USER_ID_EMPTY: '主键标识不能为空！',
        USER_USERNAME_EMPTY: '用户名不能为空！',
        USER_CODE_EMPTY: '代码不能为空！',
        USER_ENTER_USER_INFO: '请输入用户信息。',
        USER_PASSWORD_EMPTY: '密码不能为空！',
        USER_MEMBERS_HAVE_BEEN_INVITED: '已邀请成员',
        USER_USER_ID_EMPTY: '用户 ID 不能为空！',
        USER_USER_ROLE_EMPTY: '用户角色为空！',
        USER_ORG_ID_EMPTY: '组织标识不能为空！',
        USER_ILLEGAL_PHONE_NUMBER: '非法电话号码。',
        USER_ROLES_EMPTY: '角色不能为空！',
        USER_ENTER_MEMBER_INFO: '请输入成员信息。',
        USER_ILLEGAL_USERNAME: '非法用户名。',
        USER_USERNAME_EXISTS: '用户名已存在。',
        USER_CURRENT_USER_ORG_ID_EMPTY: '用户当前的组织标识为空。',
        USER_CURRENT_USER_ORG_ID_ERROR: '用户当前组织 ID 错误。',
        USER_ORG_ROLE_EMPTY: '组织角色不能为空。',
        ORG_DATA_NOT_EXIST: '修改的数据不存在，请检查！',
        ORG_PRIMARY_KEY_EMPTY: '主键集合不能为空！',
        ORG_ILLEGAL_DEPARTMENT_NAME: '部门名称不合法。',
        ORG_ORG_NOT_EXISTS: '该组织并不存在！',
        ORG_CURRENT_USER_ORG_ID_EMPTY: '当前用户组织标识为空。',
        ORG_ENTER_ADMIN_INFO: '请输入管理员信息。',
        ORG_ILLEGAL_ORGANIZATION_NAME: '非法组织名称。',
        ORG_NO_PERMISSION_CREATE_VAR: '您没有创建 VAR 的权限。',
        ORG_NO_PERMISSION_CREATE_DEPARTMENT: '您没有创建部门的权限。',
        ORG_NO_PERMISSION_TRANSFER_DEVICE: '您没有转移设备的权限。',
        ORG_DEPARTMENT_LEVEL_LIMIT: '部门最多可分为四级。',
        ORG_ID_EMPTY: '主键 ID 必须有值。',
        ORG_PARENT_ORG_NOT_EXISTS: '上级组织不存在！',
        ORG_PARENT_DEPARTMENT_CANNOT_BE_CHILD: '父部门不能是当前部门的子部门。',
        ORG_ORG_ID_EMPTY: '组织标识不能为空！',
        ORG_NOT_BELONG_VAR: '该组织不属于 VAR。',
        ORG_ID_SET_NOT_NULL: '主键 ID 集不能为空！',
        ORG_ORGANIZATION_NAME_EMPTY: '组织名称不能为空！',
        ORG_ILLEGAL_MAIL_ADDRESS: '非法邮件地址。',
        ORG_VAR_NOT_EXIST: 'VAR 不存在！',
        ORG_DEPARTMENT_NOT_EXIST: '该部门并不存在。',
        ORG_ORG_CONTAINS_DEVICES: '如果组织包含设备，则无法删除。',
        ORG_FORM_ID_NOT_BELONG_CURRENT_USER: '表单 ID 不属于当前用户。',
        DEVICE_OBJECT_INFO_EMPTY: '对象信息不能为空！',
        DEVICE_ID_NOT_NULL: '主键 ID 应为空。',
        DEVICE_MAC_EMPTY: 'MAC 地址不能为空！',
        DEVICE_RTTY_SSH_DO_NOT_ENABLE: 'Rtty SSH 未启用。',
        DEVICE_ID_EMPTY: '主键 ID 不能为空！',
        ORG_DATA_TO_BE_MODIFIED_NOT_EXIST: '要修改的数据不存在。请检查！',
        ORG_SELECT_ORG: '请选择一个组织。',
        ORG_ROLE_TYPE_ERROR: '角色类型错误。',
        ORG_ORGANIZATION_CATEGORY_EMPTY: '组织类别（0-VAR/1-组织/2-部门）不能为空！',
        ORG_ILLEGAL_PHONE_NUMBER: '非法电话号码。',
        ORG_SELECT_VAR: '请选择一个 VAR。',
        ORG_ILLEGAL_ORG_ID: '非法组织 ID。',
        ORG_VAR_ID_EMPTY: 'VAR ID 不能为空！',
        ORG_DEVICE_NOT_EXIST_OR_NOT_BELONG_VAR: '要传输的设备不存在或不属于此 VAR。',
        ORG_ORG_OR_VAR_NOT_EXIST: '组织或 VAR 不存在。',
        DEVICE_REGION_EMPTY: '区域不能为空！',
        DEVICE_DATA_TO_BE_MODIFIED_NOT_EXIST: '要修改的数据不存在。请检查！',
        DEVICE_ORG_ID_EMPTY: '组织标识不能为空。',
        DEVICE_SN_EMPTY: 'SN 不能为空。',
        DEVICE_ORG_NOT_EXIST: '该组织并不存在！',
        DEVICE_FIRMWARE_VERSION_EMPTY: '固件版本不能为空。',
        DEVICE_ILLEGAL_GROUP_NAME: '组名非法。',
        DEVICE_GROUP_NOT_BELONG_ORG: '设备组不属于组织！',
        ORG_SELECT_DEPARTMENT: '请选择一个部门。',
        DEVICE_ONLY_PERSONAL_USERS: '只有个人用户才能调用该界面。',
        DEVICE_CAN_NOT_FIND_DEVICE: '无法找到设备',
        DEVICE_RTTY_WEB_DO_NOT_ENABLE: 'Rtty Web 未启用。',
        DEVICE_SHARE_STATUS_PARAM_ERROR: '设备 share_status 参数错误！',
        DEVICE_DEVICE_ID_EMPTY: '设备 ID 不能为空。',
        DEVICE_GROUP_NAME_EXISTS: '组名已存在。',
        DEVICE_DEVICE_INFO_NOT_EXIST: '设备信息不存在！',
        DEVICE_SELECTED_DEVICE_MODEL_VERSIONS_INCONSISTENT: '所选设备型号版本不一致！',
        ROLE_DATA_TO_BE_MODIFIED_NOT_EXIST: '要修改的数据不存在。请检查！',
        ROLE_ID_SET_NOT_NULL: '主键 ID 集不能为空！',
        ROLE_ROLE_ASSIGNED_USER: '该角色分配给用户，不能删除。',
        ROLE_OBJECT_INFO_EMPTY: '对象信息不能为空！',
        ROLE_ROLE_TYPE_EMPTY: '角色类型（0-背景/1-VAR/2-ORG/3-DDNS）不能为空！',
        ROLE_ID_EMPTY: '主键标识不能为空！',
        ROLE_PERMISSION_URL_EMPTY: '权限 URL 不能为空！',
        IOT_SN_EMPTY: 'SN 不能为空！',
        IOT_USER_ID_EMPTY: '用户 ID 不能为空。',
        IOT_DEFAULT_FLAG_EMPTY: '默认标记不能为空！',
        IOT_FAVORITES_NAME_EXISTS: '收藏夹名称已存在。',
        IOT_ON_OFF_STATUS_EMPTY: '开和关状态不能为空！',
        TEMPLATE_SOURCE_ENTITY_ID_EMPTY: '源实体 ID 不能为空！',
        DEVICE_DDNS_EMPTY: 'DDNS 不能为空。',
        DEVICE_UPLOAD_LIMIT: '一次最多可上传 10000 台设备。',
        DEVICE_GROUP_NAME_EMPTY: '组名不能为空。',
        DEVICE_DEVICE_NOT_EXIST: '设备不存在！',
        DEVICE_DEVICE_NOT_FOUND: '未找到设备！',
        DEVICE_DEVICES_MUST_BELONG_SAME_VAR: '设备必须属于同一 VAR。',
        DEVICE_DEVICES_MUST_BELONG_SAME_ORG: '设备必须属于同一组织。',
        DEVICE_MODEL_CODE_EMPTY: '模型代码不能为空。',
        DEVICE_GROUP_NOT_EXISTS: '该组不存在！',
        DEVICE_SAME_GROUP_MODEL_FIRMWARE: '同一组中的设备应具有相同的型号和固件版本。',
        DEVICE_TAGS_EMPTY: '标签不能为空。',
        DEVICE_SELECT_AT_LEAST_ONE_DEVICE: '请至少选择一个设备。',
        DEVICE_ILLEGAL_DEVICE: '该设备是非法的。',
        DEVICE_DEVICE_INFO_ERROR: '设备信息错误。',
        DEVICE_DEVICE_NAME_EMPTY: '设备名称不能为空。',
        ROLE_ROLE_NAME_EXIST: '角色名称存在。',
        ROLE_ID_NOT_NULL: '主键 ID 应为空。',
        ROLE_ILLEGAL_ROLE_NAME: '非法角色名称。',
        ROLE_ROLE_NAME_EXISTS: '角色名称存在。',
        DEVICE_GROUP_ID_EMPTY: '设备组 ID 不能为空。',
        DEVICE_SELECT_DEVICE: '请选择设备。',
        DEVICE_DEVICE_ID_LIST_EMPTY: '设备 ID 列表不能为空。',
        ROLE_ROLE_NAME_EMPTY: '角色名称不能为空！',
        ROLE_PERMISSION_CATEGORY_EMPTY: '权限类别（0-菜单/1-页面/2-按钮）不能为空！',
        ROLE_VIEWER_EXIST: '查看器存在。',
        ROLE_FIRST_GROUP_MUST_BE_VIEWER: '第一组必须是观众。',
        ROLE_PERMISSION_ID_EMPTY: '权限 ID 不能为空！',
        ROLE_PERMISSION_GROUP_ID_EMPTY: '权限组 ID 不能为空！',
        TPA_DEV_NOT_VERSION: '未找到设备版本信息，设备可能未激活。',
        TPA_DEV_ALREADY_BINDED: '该设备已被他人添加。',
        IOT_DATA_NOT_EXIST: '数据不存在，请检查！',
        IOT_ID_NOT_NULL: '主键 ID 应为空。',
        IOT_MODEL_EMPTY: '设备型号不能为空。',
        IOT_BRIGHTNESS_EMPTY: '主灯不能为空！',
        ROLE_PERMISSION_NAME_EMPTY: '权限名称不能为空！',
        ROLE_APP_CODE_EMPTY: '应用程序代码不能为空！',
        ROLE_PERMISSION_GROUP_NAME_EMPTY: '权限组名称不能为空！',
        ROLE_PARENT_ID_EMPTY: '父 ID 不能为空！',
        SYSTEM_ADMIN_UPDATE_TYPE_ERROR: 'updateType 参数不正确。',
        IOT_OBJECT_INFO_EMPTY: '对象信息不能为空！',
        IOT_PUSH_TYPE_EMPTY: '推送类型不能为空！',
        IOT_PUSH_VERSION_EMPTY: '推送版本不能为空！',
        IOT_LIMIT_TIME_EMPTY: '限制时间不能为空！',
        IOT_OPERATION_STATUS_EMPTY: '操作状态不能为空！',
        IOT_RECIPIENT_ID_EMPTY: '收件人标识不能为空！',
        IOT_ID_EMPTY: '主键标识不能为空！',
        TEMPLATE_CANNOT_FIND_VERSION: '找不到版本。',
        DICT_EXIST: '数据字典已存在，不允许重复创建。',
        DICT_NO_EXIST: '字典数据不存在。',
        TPA_OWNER_EXIST: '此电子邮件已成为另一个组织的所有者。',
        IOT_FAVORITE_NOT_EXIST: '收藏夹不存在，请检查！',
        IOT_FAVORITES_NAME_EMPTY: '收藏夹名称不能为空！',
        IOT_DATA_TO_DELETE_NOT_EXIST: '要删除的数据不存在，请检查！',
        IOT_ROOM_NOT_EXIST: '房间不存在，请检查！',
        IOT_ROOM_NAME_EXISTS: '房间名称已存在。',
        IOT_DATA_TO_BE_MODIFIED_NOT_EXIST: '要修改的数据不存在。请检查！',
        TEMPLATE_CANNOT_FIND_MODEL: '找不到模型。',
        TEMPLATE_TARGET_IDS_EMPTY: '目标 ID 不能为空！',
        DICT_LABEL_EXIST: '不允许重复数据字典标签。',
        DICT_LABEL_NO_EXIST: '标签数据不存在。',
        INVITE_HAS_ACCEPTED: '邀请已经被接受'
    }
}
