<!--
 * @Description: 菜单子项
 * @Author: warren
 * @LastEditors: warren
 * @Date: 2023-04-12 09:55:58
 * @LastEditTime: 2023-04-23 10:08:50
-->
<template>
    <div v-if="!item.hidden">
        <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
            <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
                <el-menu-item :index="onlyOneChild.name" :data-te="onlyOneChild.name" :class="{'submenu-title-noDropdown':!isNest}">
                    <svg-icon v-if="onlyOneChild.meta&&onlyOneChild.meta.icon" :icon-class="onlyOneChild.meta.icon" />
                    <span v-if="onlyOneChild.meta&&onlyOneChild.meta.title" slot="title">{{ generateTitle(onlyOneChild.meta.title) }}</span>
                </el-menu-item>
            </app-link>
        </template>

        <el-submenu v-else ref="subMenu" :index="item.name" popper-append-to-body :data-te="item.name">
            <template slot="title">
                <svg-icon v-if="item.meta&&item.meta.icon" :icon-class="item.meta.icon" />
                <span v-if="item.meta&&item.meta.title" slot="title">{{ generateTitle(item.meta.title) }}</span>
            </template>
            <sidebar-item
                v-for="child in item.children"
                :key="child.path"
                :is-nest="true"
                :item="child"
                :base-path="resolvePath(child.path)"
                class="nest-menu"
            />
        </el-submenu>
    </div>
</template>

<script>
import { isExternal } from '@/utils/validate'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'
import { generateTitle } from '@/utils/i18n'

export default {
    name: 'SidebarItem',
    components: { AppLink },
    mixins: [FixiOSBug],
    props: {
    // route object
        item: {
            type: Object,
            required: true
        },
        isNest: {
            type: Boolean,
            default: false
        },
        basePath: {
            type: String,
            default: ''
        }
    },
    data () {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
        this.onlyOneChild = null
        return {}
    },
    methods: {
        generateTitle,
        hasOneShowingChild (children = [], parent) {
            const showingChildren = children.filter(item => {
                if (item.hidden) {
                    return false
                } else {
                    // Temp set(will be used if only has one showing child)
                    this.onlyOneChild = item
                    return true
                }
            })

            // When there is only one child router, the child router is displayed by default
            if (showingChildren.length === 1) {
                return true
            }

            // Show parent if there are no child router to display
            if (showingChildren.length === 0) {
                this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
                return true
            }

            return false
        },
        resolvePath (routePath) {
            if (isExternal(routePath)) {
                return routePath
            }
            if (isExternal(this.basePath)) {
                return this.basePath
            }
            function resolvePath (...paths) {
                let resolvedPath = ''
                for (const path of paths) {
                    resolvedPath = resolvedPath.concat('/', path)
                }
                return resolvedPath.replace(/\/{2,}/g, '/')
            }
            return resolvePath(this.basePath, routePath)
        }
    }
}
</script>
