import store from '@/store'

/**
 * @param {Array} value
 * @returns {Boolean}
 */

export default function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    const resources = store.getters && store.getters.permission_resources
    const permissionResources = value

    const hasPermission = resources.some(resource => {
      let flag = false
      permissionResources.forEach(per => {
        if (resource.type === 2 && per.method === resource.method && per.url === resource.url) flag = true
      })
      return flag
    })

    return hasPermission
  } else {
    console.error(`need resources! Like v-permission="[{ method: 'get', url: 'group' },{ method: 'post', url: 'group' }]"`)
    return false
  }
}

