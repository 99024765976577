/*
 * @Description:
 * @Author: warren
 * @LastEditors: warren
 * @Date: 2023-08-30 11:29:55
 * @LastEditTime: 2023-08-30 14:46:13
 */
import JsEncrypt from 'jsencrypt'

/* 加密数据 */
const getEncryptionHex = function (value) {
    const publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAItoR8lrBZ/ZaJZ3XvvgP8I31ImaTwbEPzPElmIZAasWoAzw3InqMVyeL7rTlFS3TFz3HMKBnrFlr463Bu19Tz0CAwEAAQ=='
    const encrypt = new JsEncrypt()
    encrypt.setPublicKey(publicKey)

    return encrypt.encrypt(value)
}

export {
    getEncryptionHex
}
