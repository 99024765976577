import checkPermission from '@/utils/permission.js'

// check permission of the global api
export const apiPermission = {
  name: 'apiPermission',
  computed: {

    /* -------------------------------权限管理------------------------------- */

    /**
     * @module -------------------------ssl证书管理-------------------------
     * @function isKickOutUser => kick out login
     */
    isGetSSLList() {
      return checkPermission([{ method: 'get', url: '/ssl' }])
    },

    /**
     * @module -------------------------用户管理-------------------------
     * @function isKickOutUser => kick out login
     */
    isKickOutUser() {
      return checkPermission([{ method: 'post', url: '/service/users/logout' }])
    },

    /**
     * @module -------------------------应用管理-------------------------
     * @function isAddApplication => add application
     * @function isModifyApplication => edit application
     * @function isDetleteApplication => delete application
     */
    isAddApplication() {
      return checkPermission([{ method: 'post', url: '/app/add' }])
    },
    isModifyApplication() {
      return checkPermission([{ method: 'put', url: '/app/edit' }])
    },
    isDetleteApplication() {
      return checkPermission([{ method: 'post', url: '/app/delete' }])
    },

    /**
     * @module -------------------------角色管理-------------------------
     * @function isAddUser => add user
     * @function isModifyUser => modify user
     * @function isDeleteUser => delete user
     */
    isAddUser() {
      return checkPermission([{ method: 'post', url: '/role/add' }])
    },
    isModifyUser() {
      return checkPermission([{ method: 'put', url: '/role/edit' }])
    },
    isDeleteUser() {
      return checkPermission([{ method: 'post', url: '/role/delete' }])
    },

    /**
     * @module -------------------------资源管理-------------------------
     * @function isAddResource => add resource
     * @function isModifyResource => modify resource
     * @function isDeleteResource => delete resource
     */
    isAddResource() {
      return checkPermission([{ method: 'post', url: '/permission/add' }])
    },
    isModifyResource() {
      return checkPermission([{ method: 'put', url: '/permission/edit' }])
    },
    isDeleteResource() {
      return checkPermission([{ method: 'post', url: '/permission/delete' }])
    },
    /* -------------------------------运维管理------------------------------- */
    /**
     * @module -------------------------邮箱管理-------------------------
     * @function isSetNotification => set notification email
     */
    isSetNotification() {
      return checkPermission([{ method: 'post', url: '/email/enable' }])
    }
  }
}
