export default {
    firmwareConfig: {
        tip: '提示',
        snCode: '设备S/N',
        currentVer: '当前版本',
        // limitType: '限制类型',
        // limitVer: '限制版本',
        limitTime: '限制时间',
        pushType: '推送类型',
        pushVer: '推送版本',
        // pushFirmware: '推送固件',
        inputSnCode: '请输入设备S/N码',
        // selectLimitType: '请选择限制类型',
        // selectLimitVer: '请选择限制版本',
        selectLimitTime: '请选择限制时间',
        selectPushType: '请选择推送类型',
        selectPushVersion: '请选择推送版本',
        // selectLimitedVer: '请选择限制版本',
        pushToDevice: '您确定要推送 <b>{pushVersion}</b> 至设备 <b>{sn}</b> 吗?',
        // operationUser: '操作人',
        // operationTime: '操作时间',
        yes: '是',
        no: '否',
        oneDay: '一天',
        threeDays: '三天',
        sevenDays: '七天',
        thirtyDays: '三十天',
        oneHundredAndEighty: '一百八十天',
        threeHundredAndSixtyFive: '三百六十五天',
        permanent: '永久',
        // not: '无',
        officialVersion: '正式版本',
        testVersion: '测试版本',
        successPush: '推送成功',
        deviceMac: '设备MAC',
        beginTime: '开始时间',
        endTime: '结束时间',
        status: '状态',
        operation: '操作',
        addRestrictionRule: '添加限制规则',
        limitTimeTip: '限制时间为该条任务生效的时间周期',
        pushTypeTip: '请根据实际需求选择推送类型',
        pushVersionTip: '请根据实际需求选择具体推送的版本号',
        inputDeviceMac: '请输入设备MAC',
        selectTaskStatus: '请选择任务状态',
        snLabel: 'S/N',
        mac: 'MAC',
        model: '型号',
        bindAccount: '绑定账号',
        inProgress: '进行中',
        expired: '已过期',
        disabled: '已禁用',
        disable: '禁用',
        enable: '启用',
        delete: '删除',
        inputCodeLabel: '设备S/N码或MAC',
        deviceInfo: '设备信息',
        restrictions: '限制条件',
        firmConSys: '固件配置系统',
        search: '查询',
        submit: '提交',
        selectArea: '选择区域',
        inputCodeLabelTip: '请输入设备S/N码或MAC',
        nextStep: '下一步',
        back: '上一步',
        jp: '亚太',
        eu: '欧洲',
        us: '美国',
        cn: '中国',
        reset: '重置',
        operationSuccessful: '操作成功',
        deviceNotExist: '设备不存在'
    }
}
