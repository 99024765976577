/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * validate two factor Authenticator code
 * @param code
 * @returns {boolean}
 */
export function isValidateTwoFACode(code) {
  if (code && code.length !== 6) {
    return false
  }
  const re = /^[0-9]*$/
  return re.test(code)
}

/**
 * validate phone
 * @param phone
 * @returns {boolean}
 */
export function isvalidatePhone(phone) {
  const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  return reg.test(phone)
}

/**
 * validate email
 * @param email
 * @returns {boolean}
 */
export function isValidateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

/**
 *
 * @param {发到邮箱的验证码，4位数字} emailcode
 */
export function isValidateEmailCode(emailcode) {
  const re = /^[0-9]{4}$/
  return re.test(emailcode)
}
