import Vue from 'vue'
import Router from 'vue-router'

/* Layout */
import Layout from '@/layout'
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    /* 添加 catch 不抛出错误 */
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
// 通用路由表 eg. login | 404
export const constantRouterMap = [
    {
        path: '/login',
        component: () => import('@/views/login/index'),
        hidden: true
    },
    {
        path: '/twofactor',
        component: () => import('@/views/login/twofactor'),
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/404'),
        hidden: true
    },
    {
        path: '/',
        component: Layout,
        redirect: '/dashboard',
        children: [{
            path: 'dashboard',
            name: 'dashboard',
            component: () => import('@/views/dashboard/index'),
            meta: { title: 'Dashboard', icon: 'dashboard' }
        }]
    },
    {
        path: '/',
        component: Layout,
        redirect: '/dashboard',
        children: [{
            path: 'version',
            name: 'Version',
            component: () => import('@/views/version/index'),
            meta: { title: 'Version', icon: 'dashboard' }
        }]
    },
    {
        path: '/',
        component: Layout,
        redirect: '/dashboard',
        children: [{
            path: 'upload',
            name: 'Firmware',
            component: () => import('@/views/upload/index'),
            meta: { title: 'Firmware', icon: 'dashboard' }
        }]
    },
    {
        path: '/firmware',
        component: Layout,
        redirect: '/firmware/config',
        children: [{
            path: 'config',
            name: 'firmwareConfig',
            component: () => import('@/views/firmwareConfig/index'),
            meta: { title: 'firmwareConfig', icon: 'firmware-config' }
        }]
    },
    { // 型号管理  后期要移入权限管理
        path: '/model',
        component: Layout,
        redirect: '/model/list',
        name: 'model',
        meta: { title: 'Model', icon: 'permission' },
        children: [
            {
                path: 'list',
                name: 'Model',
                component: () => import('@/views/model/index'),
                meta: { title: 'Model', icon: 'model' }
            },
            {
                path: 'resource',
                name: 'resource',
                component: () => import('@/views/modelResource/index'),
                meta: { title: 'ModelResource', icon: 'model-resource' }
            }
        ]
    },

    /* 组织管理 */
    {
        path: '/organization',
        component: Layout,
        redirect: '/organization/list',
        name: 'Organization',
        meta: { title: 'organization', icon: 'permission' },
        children: [
            {
                path: 'list',
                name: 'organizationList',
                component: () => import('@/views/organization/index.vue'),
                meta: { title: 'organization', icon: 'dashboard' }
            },
        ]
    },
    /* var管理 */
    {
        path: '/var',
        component: Layout,
        redirect: '/var/list',
        name: 'Var',
        meta: { title: 'var', icon: 'dashboard' },
        children: [
            {
                path: 'list',
                name: 'varList',
                component: () => import('@/views/var/index.vue'),
                meta: { title: 'var', icon: 'dashboard' }
            },
        ]
    }, /* 数据字典 */
    {
        path: '/dataDictionary',
        component: Layout,
        redirect: '/dataDictionary/list',
        name: 'DataDictionary',
        meta: { title: 'dataDictionary', icon: 'dashboard' },
        children: [
            {
                path: 'list',
                name: 'DataDictionaryList',
                component: () => import('@/views/dataDictionary/index.vue'),
                meta: { title: 'dataDictionary', icon: 'dashboard' }
            },
            {
                path: 'data',
                name: 'DataDictionaryDetail',
                component: () => import('@/views/dataDictionary/dataDictDetail.vue'),
                meta: { title: 'dataDictionaryDetail', icon: 'dashboard' },
                hidden: true
            },
        ]
    },
    {
        path: '/permissionGroup',
        name: 'PermissionGroup',
        component: Layout,
        meta: { title: 'PermissionGroup', icon: 'model' },
        redirect: '/permissionGroup/list',
        children: [
            {
                path: 'list',
                name: 'PermissionGroupList',
                component: () => import('@/views/permissionGroup/index.vue'),
                meta: { title: 'PermissionGroup', icon: 'model' }
            },
        ]

    },
    { // 日志管理
        path: '/log',
        component: Layout,
        redirect: '/log/applicationServiceLog',
        name: 'log',
        meta: { title: 'Log', icon: 'permission' },
        children: [
            {
                path: 'applicationServiceLog',
                name: 'ApplicationServiceLog',
                component: () => import('@/views/applicationServiceLog/index'),
                meta: { title: 'ApplicationServiceLog', icon: 'model' }
            },
            {
                path: 'deviceLog',
                name: 'DeviceLog',
                component: () => import('@/views/deviceLog/index'),
                meta: { title: 'DeviceLog', icon: 'model' }
            },
            {
                path: 'activityLog',
                name: 'ActivityLog',
                component: () => import('@/views/activityLog/index'),
                meta: { title: 'ActivityLog', icon: 'model' }
            },
            // {
            //     path: 'tracingLog',
            //     name: 'TracingLog',
            //     component: () => import('@/views/tracingLog/index'),
            //     meta: { title: 'TracingLog', icon: 'model' }
            // },
        ]
    },
]

export default new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap // 实例化vue的时候只挂载constantRouter
})

// 异步挂载路由 (需要根据权限加载的路由表)
export const asyncRouterMap = [
    { // 权限管理
        path: '/permission',
        component: Layout,
        redirect: '/permission/user',
        name: 'Permission',
        meta: { title: 'Authority', icon: 'permission' },
        children: [
            {
                path: 'user',
                name: 'User',
                component: () => import('@/views/user/index'),
                meta: { title: 'User', icon: 'user', requireAPI: { method: 'GET', url: '/users' } },
                // children: [
                //     {
                //         hidden: true,
                //         path: 'userInfo/:id(\\d+)',
                //         name: 'UserInfo',
                //         component: () => import('@/views/userInfo/index'),
                //         meta: { title: 'UserInfo' }
                //     }
                // ]
            },
            {
                path: 'application',
                name: 'Application',
                component: () => import('@/views/application/application'),
                meta: { title: 'Application', icon: 'application', requireAPI: { method: 'GET', url: '/apps' } }
            },
            {
                path: 'role',
                name: 'Role',
                component: () => import('@/views/role/index'),
                meta: { title: 'Roles', icon: 'role', requireAPI: { method: 'GET', url: '/roles' } }
            },
            {
                path: 'resource',
                name: 'Resource',
                component: () => import('@/views/role/resource/index'),
                meta: { title: 'Resource', icon: 'resource', requireAPI: { method: 'GET', url: '/permissions' } }
            },
            {
                path: 'model',
                name: 'Model',
                component: () => import('@/views/model/index'),
                meta: { title: 'Model', icon: 'model', requireAPI: { method: 'GET', url: '/model' } }
            },
            {
                path: 'modelresource',
                name: 'ModelResource',
                component: () => import('@/views/model/index'),
                meta: { title: 'ModelResource', icon: 'model', requireAPI: { method: 'GET', url: '/model_resource' } }
            },
            // {
            //     path: 'permissionGroup',
            //     name: 'PermissionGroup',
            //     component: () => import('@/views/permissionGroup/index.vue'),
            //     meta: { title: 'PermissionGroup', icon: 'model', requireAPI: { method: 'GET', url: '/permissionGroup' } }
            // },

        ]
    },

    // { // 运维管理
    //     path: '/operation',
    //     component: Layout,
    //     redirect: '/operation/s2s',
    //     name: 'Operation',
    //     meta: { title: 'Operation', icon: 'operation' },
    //     children: [
    //         {
    //             path: 'service',
    //             name: 'Service',
    //             component: () => import('@/views/service/index'),
    //             meta: { title: 'ServiceMonitoring', icon: 'service', requireAPI: { method: 'GET', url: '/services' } },
    //             children: [
    //                 {
    //                     hidden: true,
    //                     path: 'list',
    //                     name: 'User List',
    //                     component: () => import('@/views/service/user/list'),
    //                     meta: { title: 'OnlineListOfUsers' }
    //                 }
    //             ]
    //         },
    //         {
    //             path: 'device',
    //             name: 'Device',
    //             component: () => import('@/views/device/index'),
    //             meta: { title: 'DeviceMonitor', icon: 'device', requireAPI: { method: 'GET', url: '/devices' } }
    //         },
    //         {
    //             path: 's2s',
    //             name: 's2s',
    //             component: () => import('@/views/s2s/index'),
    //             meta: { title: 's2sStatistics', icon: 's2s', requireAPI: { method: 'GET', url: '/s2ss' } },
    //             children: [
    //                 {
    //                     hidden: true,
    //                     path: 'list/:id(\\d+)',
    //                     name: 'Device List',
    //                     component: () => import('@/views/s2s/device/list'),
    //                     meta: { title: 's2sDeviceList' }
    //                 }
    //             ]
    //         },
    //         {
    //             path: 'email',
    //             name: 'Email',
    //             component: () => import('@/views/email/index'),
    //             meta: { title: 'Mailbox', icon: 'email', requireAPI: { method: 'GET', url: '/emails' } }
    //         },
    //         {
    //             path: 'backup',
    //             name: 'Backup',
    //             component: () => import('@/views/backup/index'),
    //             meta: { title: 'DatabaseBackup', icon: 'backup', requireAPI: { method: 'GET', url: '/db' } }
    //         }
    //     ]
    // },

    { path: '*', redirect: '/404', hidden: true }
]
