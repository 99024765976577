/*
 * @Author: Warren
 * @Date: 2024-05-09 10:32:10
 * @LastEditors: Warren
 * @LastEditTime: 2024-05-23 15:14:03
 * @FilePath: \gl-system-fronted\src\utils\dateTime.js
 * @Description: 处理时间  全局挂载到 this.$date
 */
import dayJs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import store from '@/store'

const BASE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

dayJs.extend(utc)
dayJs.extend(timezone)

/* 是否是时间戳 */
function isTimestamp (timestamp) {
    return /^\d{13}$/.test(timestamp)
}

/* 日期格式 是否正确 */
function isValidDateString (format) {
    return dayJs(format).isValid()
}

/**
 * @description: 时区是否存在
 * @param {string} zoneStr 时区字符串
 * @return {Boolean}
 */
function isValidTimeZone (zoneStr) {
    try {
        const formatter = new Intl.DateTimeFormat('en-US', { timeZone: zoneStr })
        formatter.format(new Date())
        return true
    } catch (error) {
        console.error(`timezone string error: "${zoneStr}"`, error)
        return false
    }
}

/**
 * @description: 根据时区 返回格式化后的时间字符串
 * @param {number} timestamp 时间戳
 * @param {string} format 格式化规则字符串
 * @return {string} 默认返回时间格式 'YYYY-MM-DD HH:mm:ss'
 */
function timeZoneFormat (timestamp, format) {
    const timeZone = store.state.user.userInfo.timezone

    format = format || BASE_FORMAT

    timestamp = Number(timestamp)
    if (timeZone) {
        if (isValidTimeZone(timeZone)) {
            return dayJs(timestamp).tz(timeZone).format(format)
        } else {
            return ''
        }
    }

    return dayJs(timestamp).format(format)
}

/**
 * @description: 告警规则延迟组件使用，
 * @param {number} minutes
 * @return {string}
 */
function formatMinutesToMMSS (minutes) {
    const totalSeconds = Math.round(minutes * 60) // minuts 有可能是小数或整数
    const minutesPart = Math.floor(totalSeconds / 60)
    const secondsPart = totalSeconds % 60
    return `${String(minutesPart).padStart(2, '0')}:${String(secondsPart).padStart(2, '0')}`
}

/**
 * @description: 将时间字符串转为分钟数值
 * @param {string} timeString  MM:ss
 * @return {number} 分钟
 */
function convertTimeToMinutes (timeString) {
    return timeString.split(':').map(Number)[0]
}

/**
 * @description: 获取当前时间的时间戳
 * @return {number} 时间戳
 */
function getNowTimestamp () {
    return Date.now()
}

/**
 * @description: 获取当前时间 格式化后的时间字符串
 * @param {*} format 默认格式 'YYYY-MM-DD HH:mm:ss'
 * @return {*} 格式化后的时间字符串
 */
function getNowFormatDate (format) {
    return baseDate(getNowTimestamp(), format)
}

/**
 * @description: 根据给定的日期字符串 返回时间戳
 * @param {*} dateString '2024-12-12 12:12:12'
 * @return {*} 时间戳或者空字符串
 */
function getDateTimestamp (dateString) {
    return dayJs(dateString).valueOf() || ''
}

/**
 * @description: 格式化输出时区差值
 * @param {*} timeZone
 * @return {string}  (UTC+08:00)
 */
function getUtcOffsetFormat (timeZone) {
    timeZone = timeZone || dayJs.tz.guess()
    let offset = dayJs().tz(timeZone).utcOffset()
    offset = parseInt(offset / 60)
    const offsetChart = offset < 0 ? '-' : '+'
    const valuePad = Math.abs(offset).toString().padStart(2, '0')

    return ` (UTC${offsetChart}${valuePad}:00)`
}

/**
 * @description: 判断当前账号设置的时间和本地时区是否一致
 * @return {Boolean}
 */
function isSameTimeZone () {
    const local = dayJs.tz.guess()
    const set = store.state.user.userInfo.timezone
    if (!set) {
        /* 用户初始化是没有配置该值的 所以就默认当前时区 */
        return true
    }
    return local === set
}

/**
 * @description: 日期时间组件选择的日期时间 转换为 指定时区时间戳
 * @param {string} dateTimeStr 日期时间字符串
 * @return {object} 目标时区时间戳
 */
function changeShowDateToTimezoneDate (dateTimeStr) {
    const timeZone = store.state.user.userInfo.timezone
    let dayjsObject = {}
    if (isValidTimeZone(timeZone)) {
        dayjsObject = dayJs.tz(dateTimeStr, BASE_FORMAT, timeZone)
    } else {
        dayjsObject = dayJs(dateTimeStr, BASE_FORMAT)
    }
    return dayjsObject.valueOf()
}

/**
 * @description: 统一按时区格式化显示日期时间; 全局使用 this.$date.baseDate()
 * @param { String | Number} timestamp  时间戳 1715066323154 13位
 * @param { String } format 默认 'YYYY-MM-DD HH:mm:ss'
 * @return {string} 格式化后的时间
 */
function baseDate (timestamp, format) {
    if (!isTimestamp(timestamp)) {
        console.error('BaseDate Error: ', timestamp)
        return ''
    }
    return timeZoneFormat(timestamp, format)
}

export default {
    baseDay: dayJs,
    baseDate,
    timeZoneFormat,
    getNowTimestamp,
    formatMinutesToMMSS,
    convertTimeToMinutes,
    getNowFormatDate,
    getDateTimestamp,
    getUtcOffsetFormat,
    isSameTimeZone,
    changeShowDateToTimezoneDate,
}
