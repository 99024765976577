/*
 * @Description: 校验
 * @Author: warren
 * @LastEditors: warren
 * @Date: 2023-04-20 18:06:27
 * @LastEditTime: 2023-04-21 12:23:04
 */
export default {
    validate: {
        required: '必填',
        email: '请输入正确的邮箱地址',
        url: '请输入正确的网址',
        date: '请输入正确的日期',
        inputEmptyValidateTip: '不能为空',
        overMaxLength: '不能超过{max}个字符',
        noSpecialCharacters: '不能包含特殊字符',
        phoneError: '请输入正确的手机号',
        orgNameRequired: '请输入组织名称',
        infoRequired: '信息不能为空',
        selectApplication: '请选择应用',
        selectPermission: '请选择权限',
        selectType: '请选择类型',
        inputResourceName: '请输入权限组名称',
        selectSuperiorResources: '请选择上级菜单',
        regionRequired: '请选择区域',
        snCode: '请输入一个有效的sn码',
        limitType: '请选择一个有效的限制类型',
        limitVersion: '请选择一个有效的限制版本',
        limitTime: '请选择一个有效的限制时间',
        pushVersion: '请选择一个有效的推送版本',
        pushType: '请选择一个有效的推送类型'
    }
}
