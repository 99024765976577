/**
 * @Author: wangchuan
 * @Date: 2024-07-04 14:50:05
 * @LastEditTime: 2024-07-16 09:27:07
 * @LastEditors: wangchuan
 * @FilePath: \gl-system-frontend\src\lang\errorCode\en.js
 * @Description: errorCode 翻译
 */
export default {
    message: {
        sendEmailOrPhoneFailed: 'Please relogin to confirm the user identity.',
        serverError: 'Server error.',
        i18n_invalidTokenWarning: 'Login timeout, login again.',
        permissionError: 'Unfortunately, you are not authorized to access the current page.',
        tokenExpired: 'Token Expired',
        addDeviceParam: 'Missing Parameters',
        inputParametersError: 'Input parameters error.',
        accoutLoginInOtherPlace: 'The account has been logged in somewhere else.',
        expiredLogin: 'Expired Login',
        userAlreadyExistInOrg: 'The invitation user already exists in this organization.',
        requestXssError: 'The request contains xss attack elements, please check.',
        securityAuthorizationExpired: 'The security authorization status has expired. Please log in again.',
        invaliduser2psw: 'Invalid username or password.',
        userunexist: 'User not exist.',
        confirmPasswordValidateError: 'The passwords you typed do not match.',
        passwordError: 'Password error.',
        userNamealreadyexists: 'Username already exists.',
        emailalreadyexists: 'User email already exists.',
        emailCodeError: 'Email verification code error.',
        emailerror: 'User email incorrect.',
        emailCodeExpired: 'Email verification code has expired.',
        Two_factor_code_verification_failed_Please_try_again: 'Code verification failed. Please try again.',
        sendEmailCodeFailed: 'Send email verification code unseccessful.',
        phoneCodeExpired: 'Phone verification code has expired.',
        phoneCodeError: 'Phone verification code error.',
        notYourPhone: 'The phone number is not yours.',
        phoneExists: 'The mobile number already exists.',
        phoneNotExist: 'Cell phone number does not exist.',
        sendCodeTimesError: 'The number of verification codes sent exceeded the limit.',
        regionMatch: 'Region Mismatch',

        VERIFICATION_CODE_COUNT_ERROR: 'Verification Code Error Count Exceeds Limit',
        DEVICE_LIST_IS_NULL: 'Device List Is Null',
        HAS_NOT_TRANSFER_DEVICE: 'Device Not Transferred',
        HAS_NOAUTH_DEVICE: 'Device Not Authenticated',
        ORGID_IS_NULL: 'OrgId Is Null',
        HAS_NOT_RETURNED_DEVICE: 'Device Not Returned',
        DEVICE_DDNS_MISMATCHING: 'Device DDNS Mismatch',
        ORG_ID_IS_ILLEGAL: 'OrgId Is Illegal',

        unDevices: 'Device not found.',
        devicesOffline: 'Device offline.',
        devicesTimeout: 'Set device timeout.',
        fileLengthValidateError: 'Please select the excel file to import.',
        addDeviceExcelError: 'Excel content error.',
        DEVICE_IMAGE_FORMAT_ERROR: 'Image Format Error',
        DEVICE_IMAGE_SIZE_ERROR: 'Image Size Error',
        DEVICE_FIRMWARE_NOT_FOUND: 'Cannot Dind Device Firmware',
        DEVICE_EXECUTION_FAILED: 'Execution Failed',

        one_of_the_devices_had_joined_to_another_site_to_site_network_please_remove_it_then_continue: 'One of the devices had joined to another Site to Site network, please remove it then continue.',
        infoIncorrect: 'The infomation(MAC, S/N, DDNS/Device ID) you entered is incorrect, please check the information you entered.',
        DATA_DEPEND: 'Data Depend',
        hostAlreadyExists: '',
        TEMPLATE_NAME_EXIST: 'Template Name Exists',

        SHARE_ERROR: 'Can\'t share it with yourself',
        RESULT_ERROR: 'Result Error',
        gpsNotfound: 'Device gps not found.',
        error: 'Error',
        REPETITION_SHARE_ERROR: 'The device is already shared with this user.',

        GROUP_EXISTS: 'Group exists.',
        DEVICE_EXISTS: 'Device exists.',
        TASK_EXISTS: 'Task exists.',
        TEMPLATE_EXISTS: 'Template exists.',
        SUBACCOUNT_EXISTS: 'Sub-account exists.',
        ALREADY_SHARED: 'The device is already shared with this user.',
        NOT_SUBACCOUNT: 'Not sub-account.',
        TASK_LIMIT_ILLEGAL_ERR: 'Task Count Limit Error',
        TASK_TYPE_UNSUPPORTED_ERR: 'Task Type Unsupported Error',
        TASK_REQUEST_EMPTY_ERR: 'Task Request Empty Error',
        finishAndStopfailure: 'The task has been completed. Stop failed.',

        FILE_NULL: 'Invalid argument: multipartFile cannot be null.',
        FORMAT_ERROR: 'Image Format Error',
        SIZE_ERROR: 'Image Size Error',
        AUTO_SSL_GOODCLOUD_FAILED: 'Auto SSL Goodcloud Failed',
        AUTO_SSL_RTTY_SSH_FAILED: 'Auto SSL Rtty SSH Failed',
        AUTO_SSL_RTTY_WEB_FAILED: 'Auto SSL Rtty Web Failed',
        USER_CONFIG_EXISTS: 'User Config Already Exists',
        domainLegal: 'Failed to submit. The domain name is illegal.',
        domainExit: 'Failed to submit. The domain name already exists.',
        domainError: 'Domain or SSL certificate error.',
        sslError: 'Auto SSL certificate error.',
        DNS_RECORD_ERROR: 'Dns Record Error',
        DOMAIN_MODIFY_ERROR: 'This domain cannot change domain and SSL information.',
        RPC_REQUEST_ERROR: 'Rpc Request Error',
        FAVORITE_COUNT_LIMIT: 'Number of favorites exceeds limit.',
        FAVORITE_NAME_EXISTS: 'Favorite Name Exists',
        guiClosed: 'GUI is closed.',
        EMAIL_CONFIG_ERROR: 'Email Config Error',
        // 以下是新追加
        SERVER_ERROR: 'Server Error!',
        INVALID_TOKEN: 'Invalid Token!',
        REQUEST_BUSY_ERROR: 'Too Many Requests. Try Again Later!',
        PERMISSION_ERROR: 'Permission Error!',
        DATA_ERROR: 'Data Error!',
        TPA_PARAM_ERROR: 'Param Error',
        TPA_ORG_EXIST: 'The Organization Already Exists.',
        TPA_ORG_NO_EXIST: 'The Organization Does Not Exists.',
        TPA_ILLEGAL_ORG: 'Illegal Organization Id',
        TPA_DEVICE_NO_EXIST: 'The Device Does Not Exist.',
        TPA_EMAIL_ERROR: 'Illegal Mailbox.',
        TPA_DEV_NOT_BELONG_ERROR: 'Device Not Belong To The Org',
        SYSTEM_PARENT_ID_NOT_FOUND: 'Can\'t find parent id.',
        SYSTEM_PARENT_APPLICATION_MUST_BE_FIRST_LEVEL: 'Parent application must be first level.',
        SYSTEM_OBJECT_INFO_EMPTY: 'The object information cannot be empty!',
        SYSTEM_DATA_TO_BE_MODIFIED_NOT_EXIST: 'The data to be modified does not exist. Please check!',
        SYSTEM_ID_NOT_NULL: 'The primary key ID should be null.',
        SYSTEM_ID_EMPTY: 'The primary key ID cannot be empty!',
        SYSTEM_APP_NAME_EMPTY: 'The app name cannot be empty!',
        SYSTEM_APP_CODE_EMPTY: 'The app code cannot be empty!',
        SYSTEM_ILLEGAL_APP_NAME: 'Illegal app name.',
        SYSTEM_APP_NAME_EXISTS: 'The app name exists.',
        SYSTEM_INVALID_TIME_FORMAT: 'Invalid time format.',
        SYSTEM_INVALID_ARGUMENT_MULTIPARTFILE_NULL: 'Invalid argument: multipartFile cannot be null.',
        SYSTEM_INVALID_ARGUMENT_OBJECTNAME_NULL: 'Invalid argument: objectName cannot be null or empty.',
        SYSTEM_MAC_EMPTY: 'The MAC address cannot be empty!',
        SYSTEM_STATUS_EMPTY: 'The status(1-success /2-failure) cannot be empty!',
        SYSTEM_INVALID_VERSION_NUMBER: 'The version number is not valid! It can only contain numbers, letters, dots, and hyphens. The length should be between 1 and 30.',
        USER_SEND_MODE_EMPTY: 'The sendMode(email/msg/site messages) cannot be empty!',
        USER_RECIPIENT_ID_EMPTY: 'The recipient id cannot be empty!',
        USER_PHONE_EMPTY: 'The phone cannot be empty!',
        USER_EMAIL_EMPTY: 'The email cannot be empty!',
        USER_ACCOUNT_EMPTY: 'The account cannot be empty!',
        USER_SELECT_ORG: 'Please select an organization.',
        USER_NOT_EXISTS: 'User not exists.',
        USER_ORG_NOT_EXISTS: 'The organization is not exists!',
        USER_CANNOT_DELETE_ADMINISTRATOR: 'Cannot delete Administrator!',
        USER_PASSWORD_NOT_MATCH: 'User password not match.',
        USER_ORG_NOT_BELONG_VAR: 'The organization is not belong to the VAR.',
        USER_SELECT_ROLE: 'Please select a role.',
        ORG_ORGANIZATION_NAME_EXIST: 'The organization name already exists.',
        ORG_ILLEGAL_ORG_NAME: 'Illegal organization name.',
        ORG_CANNOT_FIND_ORG: 'Cannot find the organization.',
        ORG_DATA_TO_BE_MODIFIED_NOT_EXIST: 'The data to be modified does not exist. Please check!',
        SYSTEM_USER_ID_EMPTY: 'The user ID cannot be empty!',
        USER_OBJECT_INFO_EMPTY: 'The object information cannot be empty!',
        USER_ID_NOT_NULL: 'The primary key ID should be null.',
        USER_UPDATE_EMAIL_EMPTY: 'The email address cannot be empty!',
        USER_UPDATE_USER_TOKEN_EMPTY: 'The user token cannot be empty!',
        USER_CANNOT_DELETE_YOURSELF: 'Cannot delete yourself!',
        USER_ENTER_MEMBER_ID: 'Please enter the member ID.',
        USER_ILLEGAL_MAIL_ADDRESS: 'Illegal mail address.',
        USER_INVITATION_EXPIRED: 'The invitation has expired.',
        USER_RE_PASSWORD_EMPTY: 'The re-password cannot be empty!',
        USER_CANNOT_FIND_ORG: 'Cannot find the organization.',
        ORG_DEPARTMENT_NAME_EXIST: 'The department name already exists.',
        ORG_PARENT_ORG_NOT_EXIST: 'The parent organization does not exist!',
        ORG_DELETE_DEPARTMENT_ERROR: 'This function only delete department!',
        ORG_OBJECT_INFO_EMPTY: 'The object information cannot be empty!',
        ORG_ID_NOT_NULL: 'The primary key ID should be null.',
        ORG_DEPARTMENT_NAME_EMPTY: 'The department name cannot be empty!',
        ORG_PARENT_ID_EMPTY: 'The parent ID cannot be empty!',
        ORG_DEPARTMENT_LEVEL_LIMIT: 'There can be at most four levels of departments.',
        SYSTEM_SQL_INVALID_PARAMETER: 'The parameter %s is invalid. Please check it.',
        SYSTEM_TEMPERATURE_THRESHOLD_RANGE: 'The temperature threshold range must be between -10 and 59.',
        USER_DATA_TO_BE_MODIFIED_NOT_EXIST: 'The data to be modified does not exist. Please check!',
        USER_SEND_STATUS_EMPTY: 'The sendStatus(0-success /1-failure) cannot be null!',
        USER_ID_EMPTY: 'The primary key ID cannot be empty!',
        USER_USERNAME_EMPTY: 'The username cannot be empty!',
        USER_CODE_EMPTY: 'The code cannot be empty!',
        USER_ENTER_USER_INFO: 'Please enter the user information.',
        USER_PASSWORD_EMPTY: 'The password cannot be empty!',
        USER_MEMBERS_HAVE_BEEN_INVITED: 'Members have already been invited',
        USER_USER_ID_EMPTY: 'The user id cannot be empty!',
        USER_USER_ROLE_EMPTY: 'The user role is empty!',
        USER_ORG_ID_EMPTY: 'The organization ID cannot be empty!',
        USER_ILLEGAL_PHONE_NUMBER: 'Illegal phone number.',
        USER_ROLES_EMPTY: 'The roles cannot be empty!',
        USER_ENTER_MEMBER_INFO: 'Please enter the member information.',
        USER_ILLEGAL_USERNAME: 'Illegal username.',
        USER_USERNAME_EXISTS: 'The username already exists.',
        USER_CURRENT_USER_ORG_ID_EMPTY: 'The user\'s current organization ID is empty.',
        USER_CURRENT_USER_ORG_ID_ERROR: 'The user current organization ID is error.',
        USER_ORG_ROLE_EMPTY: 'The organization role cannot be empty.',
        ORG_DATA_NOT_EXIST: 'Modified data does not exist, please check!',
        ORG_PRIMARY_KEY_EMPTY: 'The primary key set cannot be empty!',
        ORG_ILLEGAL_DEPARTMENT_NAME: 'Illegal department name.',
        ORG_ORG_NOT_EXISTS: 'The organization does not exist!',
        ORG_CURRENT_USER_ORG_ID_EMPTY: 'Current user organization ID is empty.',
        ORG_ENTER_ADMIN_INFO: 'Please enter the administrator information.',
        ORG_ILLEGAL_ORGANIZATION_NAME: 'Illegal organization name.',
        ORG_NO_PERMISSION_CREATE_VAR: 'You have no permission to create a VAR.',
        ORG_NO_PERMISSION_CREATE_DEPARTMENT: 'You have no permission to create a department.',
        ORG_NO_PERMISSION_TRANSFER_DEVICE: 'You don\'t have permission to transfer the device.',
        ORG_SELECT_DEPARTMENT: 'Please select a department.',
        ORG_ID_EMPTY: 'The primary key ID must have a value.',
        ORG_PARENT_ORG_NOT_EXISTS: 'The parent organization does not exist!',
        ORG_PARENT_DEPARTMENT_CANNOT_BE_CHILD: 'The parent department cannot be a child of the current department.',
        ORG_ORG_ID_EMPTY: 'The organization ID cannot be empty!',
        ORG_NOT_BELONG_VAR: 'The organization is not belong the VAR.',
        ORG_ID_SET_NOT_NULL: 'The primary key ID set cannot be empty!',
        ORG_ORGANIZATION_NAME_EMPTY: 'The organization name cannot be empty!',
        ORG_ILLEGAL_MAIL_ADDRESS: 'Illegal mail address.',
        ORG_VAR_NOT_EXIST: 'The VAR does not exist!',
        ORG_DEPARTMENT_NOT_EXIST: 'The department does not exist.',
        ORG_ORG_CONTAINS_DEVICES: 'The organization cannot be deleted if it contains devices.',
        ORG_FORM_ID_NOT_BELONG_CURRENT_USER: 'The form ID does not belong to the current user.',
        DEVICE_OBJECT_INFO_EMPTY: 'The object information cannot be empty!',
        DEVICE_ID_NOT_NULL: 'The primary key ID should be null.',
        DEVICE_MAC_EMPTY: 'The MAC address cannot be empty!',
        DEVICE_RTTY_SSH_DO_NOT_ENABLE: 'Rtty SSH is not enabled.',
        DEVICE_ID_EMPTY: 'The primary key ID cannot be empty!',
        DEVICE_DDNS_EMPTY: 'The DDNS cannot be empty.',
        ORG_SELECT_ORG: 'Please select an organization.',
        ORG_ROLE_TYPE_ERROR: 'Role type error.',
        ORG_ORGANIZATION_CATEGORY_EMPTY: 'The organization category (0-VAR/1-Organization/2-department) cannot be empty!',
        ORG_ILLEGAL_PHONE_NUMBER: 'Illegal phone number.',
        ORG_SELECT_VAR: 'Please select a VAR.',
        ORG_ILLEGAL_ORG_ID: 'Illegal organization ID.',
        ORG_VAR_ID_EMPTY: 'The VAR ID cannot be empty!',
        ORG_DEVICE_NOT_EXIST_OR_NOT_BELONG_VAR: 'The device to be transferred does not exist or does not belong to this VAR.',
        ORG_ORG_OR_VAR_NOT_EXIST: 'The organization or VAR does not exist.',
        DEVICE_REGION_EMPTY: 'The region cannot be empty!',
        DEVICE_DATA_TO_BE_MODIFIED_NOT_EXIST: 'The data to be modified does not exist. Please check!',
        DEVICE_ORG_ID_EMPTY: 'The organization ID cannot be empty.',
        DEVICE_SN_EMPTY: 'The SN cannot be empty.',
        DEVICE_ORG_NOT_EXIST: 'The organization does not exist!',
        DEVICE_FIRMWARE_VERSION_EMPTY: 'The firmware version cannot be empty.',
        DEVICE_ILLEGAL_GROUP_NAME: 'Illegal group name.',
        DEVICE_GROUP_NOT_BELONG_ORG: 'Device group does not belong to the organization!',
        DEVICE_GROUP_ID_EMPTY: 'The device group ID cannot be empty.',
        DEVICE_ONLY_PERSONAL_USERS: 'Only personal users can call this interface.',
        DEVICE_CAN_NOT_FIND_DEVICE: 'Can not find the device',
        DEVICE_RTTY_WEB_DO_NOT_ENABLE: 'Rtty Web is not enabled.',
        DEVICE_SHARE_STATUS_PARAM_ERROR: 'Device share_status param error!',
        DEVICE_DEVICE_ID_EMPTY: 'The device ID cannot be empty.',
        DEVICE_GROUP_NAME_EXISTS: 'The group name already exists.',
        DEVICE_DEVICE_INFO_NOT_EXIST: 'Device info does not exist!',
        DEVICE_SELECTED_DEVICE_MODEL_VERSIONS_INCONSISTENT: 'The selected device model versions are inconsistent!',
        ROLE_DATA_TO_BE_MODIFIED_NOT_EXIST: 'The data to be modified does not exist. Please check!',
        ROLE_ID_SET_NOT_NULL: 'The primary key ID set cannot be empty!',
        ROLE_OBJECT_INFO_EMPTY: 'The object information cannot be empty!',
        ROLE_ROLE_TYPE_EMPTY: 'The role type (0-background/1-VAR/2-ORG/3-DDNS) cannot be empty!',
        ROLE_ID_EMPTY: 'The primary key ID cannot be empty!',
        ROLE_PERMISSION_URL_EMPTY: 'The permission URL cannot be empty!',
        DEVICE_UPLOAD_LIMIT: 'A maximum of 10000 devices can be uploaded at a time.',
        DEVICE_GROUP_NAME_EMPTY: 'The group name cannot be empty.',
        DEVICE_DEVICE_NOT_EXIST: 'The device does not exist!',
        DEVICE_DEVICE_NOT_FOUND: 'The device was not found!',
        DEVICE_DEVICES_MUST_BELONG_SAME_VAR: 'Devices must belong to the same VAR.',
        DEVICE_DEVICES_MUST_BELONG_SAME_ORG: 'Devices must belong to the same organization.',
        DEVICE_MODEL_CODE_EMPTY: 'The model code cannot be empty.',
        DEVICE_GROUP_NOT_EXISTS: 'The group does not exists!',
        DEVICE_SAME_GROUP_MODEL_FIRMWARE: 'Devices in the same group should have the same model and firmware version.',
        DEVICE_TAGS_EMPTY: 'The tags cannot be empty.',
        DEVICE_SELECT_AT_LEAST_ONE_DEVICE: 'Please select at least one device.',
        DEVICE_ILLEGAL_DEVICE: 'The device is illegal.',
        DEVICE_DEVICE_INFO_ERROR: 'Device information error.',
        DEVICE_DEVICE_NAME_EMPTY: 'The device name cannot be empty.',
        ROLE_ROLE_NAME_EXIST: 'Role name exists.',
        ROLE_ID_NOT_NULL: 'The primary key ID should be null.',
        ROLE_ILLEGAL_ROLE_NAME: 'Illegal role name.',
        ROLE_ROLE_NAME_EXISTS: 'Role name exists.',
        DEVICE_SELECT_DEVICE: 'Please select a device.',
        DEVICE_DEVICE_ID_LIST_EMPTY: 'The device ID list can\'t be empty.',
        ROLE_ROLE_ASSIGNED_USER: 'This role is assigned to a user and cannot be deleted.',
        ROLE_ROLE_NAME_EMPTY: 'The role name cannot be empty!',
        ROLE_PERMISSION_CATEGORY_EMPTY: 'The permissions category (0-menu /1-page /2-button) cannot be empty!',
        ROLE_VIEWER_EXIST: 'Viewer exist.',
        ROLE_FIRST_GROUP_MUST_BE_VIEWER: 'The first group must be viewer.',
        ROLE_PERMISSION_ID_EMPTY: 'The permission ID cannot be empty!',
        ROLE_PERMISSION_GROUP_ID_EMPTY: 'The permission group ID cannot be empty!',
        TPA_OWNER_EXIST: 'This Email Has Already Become The Owner Of Another Organization.',
        ROLE_PERMISSION_NAME_EMPTY: 'The permission name cannot be empty!',
        ROLE_APP_CODE_EMPTY: 'The app code cannot be empty!',
        ROLE_PERMISSION_GROUP_NAME_EMPTY: 'The permission group name cannot be empty!',
        ROLE_PARENT_ID_EMPTY: 'The parent ID cannot be empty!',
        SYSTEM_ADMIN_UPDATE_TYPE_ERROR: 'The updateType parameter is incorrect.',
        TPA_DEV_NOT_VERSION: 'No device version information found, the device may not be activated.',
        TPA_DEV_ALREADY_BINDED: 'The device has already been Added by others.',
        IOT_SN_EMPTY: 'The SN cannot be empty!',
        IOT_USER_ID_EMPTY: 'The user ID cannot be empty.',
        IOT_DEFAULT_FLAG_EMPTY: 'The default flag cannot be empty!',
        IOT_FAVORITES_NAME_EXISTS: 'The favorites name already exists.',
        IOT_ON_OFF_STATUS_EMPTY: 'The on off status cannot be empty!',
        TEMPLATE_SOURCE_ENTITY_ID_EMPTY: 'Source Entity ID can not be empty!',
        IOT_DATA_NOT_EXIST: 'Data does not exist, please check!',
        IOT_ID_NOT_NULL: 'The primary key ID should be null.',
        IOT_MODEL_EMPTY: 'The device model cannot be empty.',
        IOT_BRIGHTNESS_EMPTY: 'The main light cannot be empty!',
        IOT_OBJECT_INFO_EMPTY: 'The object information cannot be empty!',
        IOT_PUSH_TYPE_EMPTY: 'The push type cannot be empty!',
        IOT_PUSH_VERSION_EMPTY: 'The push version cannot be empty!',
        IOT_LIMIT_TIME_EMPTY: 'The limit time cannot be empty!',
        IOT_OPERATION_STATUS_EMPTY: 'The operation status cannot be empty!',
        IOT_RECIPIENT_ID_EMPTY: 'The recipient id cannot be empty!',
        IOT_ID_EMPTY: 'The primary key ID cannot be empty!',
        TEMPLATE_CANNOT_FIND_VERSION: 'Cannot find the version.',
        DICT_EXIST: 'Data dictionary already exists, duplicate creation not allowed.',
        DICT_NO_EXIST: 'Dictionary data does not exist.',
        IOT_FAVORITE_NOT_EXIST: 'Favorite does not exist, please check!',
        IOT_FAVORITES_NAME_EMPTY: 'The favorites name cannot be empty!',
        IOT_DATA_TO_DELETE_NOT_EXIST: 'The data to delete does not exist, please check!',
        IOT_ROOM_NOT_EXIST: 'Room does not exist, please check!',
        IOT_ROOM_NAME_EXISTS: 'The room name already exists.',
        IOT_DATA_TO_BE_MODIFIED_NOT_EXIST: 'The data to be modified does not exist. Please check!',
        TEMPLATE_CANNOT_FIND_MODEL: 'Cannot find the model.',
        TEMPLATE_TARGET_IDS_EMPTY: 'Target IDs can not be empty!',
        DICT_LABEL_EXIST: 'Duplicate data dictionary label not allowed.',
        DICT_LABEL_NO_EXIST: 'Label data does not exist.',
        INVITE_HAS_ACCEPTED: 'The Invitation Has Been Accepted'
    }
}
