
/**
 * init global utility filters.
 */

/**
 * @param {string} 1576555640
 * @returns {2019-12-17 12:07:08}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) return null

  if (time === null) return null

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  // online first time, its offline time is '1970-01-01 08:00:00.0'
  if (formatObj.y === 1970) return ''

  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/**
 * @param {string} e4956e4397bc
 * @returns {E4:95:6E:43:97:BC}
 */
export function macAddressFormatter(strMac) {
  if (!strMac) {
    return null
  }
  const v = strMac.toUpperCase()
  const colon = ':'
  let output
  if (v.length === 12) {
    output = [v.slice(0, 2), colon, v.slice(2, 4), colon, v.slice(4, 6), colon, v.slice(6, 8), colon, v.slice(8, 10), colon, v.slice(10)].join('')
    return output
  }
  return strMac
}

// format ap band
export function apBandFormatter(str) {
  if (str === '2G') {
    return '2.4G'
  }
  return str
}

// format Device Type
export function deviceTypeFormatter({ type, services, mode }) {
  if (services && services.length > 2) return JSON.parse(services)[0].type
  if (mode === 'router' || mode === null) return type
  return mode
}

// filter operation log status
export function logOperationStatusFilter(str) {
  if (str === 1) {
    return 'success'
  } else if (str === 2) {
    return 'failure'
  } else if (str === 3) {
    return 'unknown'
  }
}

// format device model
export function deviceModelFormatter(strModel) {
  if (strModel) {
    switch (strModel) {
      case 'n300':
        strModel = 'microuter-' + strModel.toUpperCase()
        break
      default:
        strModel = 'GL-' + strModel.toUpperCase()
    }
  }
  return strModel
}

