<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    {{ collapse }}
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <img v-if="logomini" :src="logomini" class="sidebar-logo" width="25">
        <!-- <h1 v-else class="sidebar-title">{{ title }} </h1> -->
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link sidebar-logo-main" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo" width="260">
        <!-- <h1 class="sidebar-title">{{ title }} </h1>  -->
      </router-link>
    </transition>
  </div>
</template>

<script>
import logo from '@/assets/logo/logo.png'
import logomini from '@/assets/logo/logo_mini.png'
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: 'GL-iNet',
      logo,
      logomini
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  // height: 50px;
  // line-height: 50px;
  // background: #2b2f3a;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    height: 36px;
    display: flex!important;
    justify-content: center;
    align-items: center;

    &.sidebar-logo-main {
      height: 110px;
    }

    & .sidebar-logo {
      // width: 32px;
      // height: 32px;
      vertical-align: middle;
      margin-right: 6px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
