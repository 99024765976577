export default {
    firmwareConfig: {
        tip: 'Tip',
        snCode: 'Equipment S/N code',
        currentVer: 'Current Version',
        // limitType: 'Limit Type',
        // limitVer: 'Limit Version',
        limitTime: 'Limit Time',
        pushType: 'Push Type',
        pushVer: 'Push version',
        // pushFirmware: 'Push Firmware',
        inputSnCode: 'Please enter the device S/N code',
        // selectLimitType: 'Please select a limit type',
        // selectLimitVer: 'Please select a limit version',
        selectLimitTime: 'Please select a limited time',
        selectPushType: 'Please select a push type',
        selectPushVersion: 'Please select the push version',
        // selectLimitedVer: 'Please select a limited version',
        pushToDevice: 'Are you sure you want to push <b>{pushVersion}</b> to device <b>{sn}</b>?',
        // operationUser: 'Operator',
        // operationTime: 'Operation Time',
        yes: 'Yes',
        no: 'No',
        oneDay: 'One day',
        threeDays: 'Three days',
        sevenDays: 'Seven days',
        thirtyDays: 'Thirty days',
        oneHundredAndEighty: 'One hundred and eighty',
        threeHundredAndSixtyFive: 'Three hundred and sixty-five',
        permanent: 'Permanent',
        // not: 'Not',
        officialVersion: 'Official Version',
        testVersion: 'Test Version',
        successPush: 'Successfully pushed',
        deviceMac: 'Device MAC',
        beginTime: 'Start Time',
        endTime: 'End Time',
        status: 'Status',
        operation: 'Operation',
        addRestrictionRule: 'Add restriction rule',
        limitTimeTip: 'Limit the time to the effective time period of this task',
        pushTypeTip: 'Please select the push type based on actual needs',
        pushVersionTip: 'Please select the specific version number to push based on actual needs',
        inputDeviceMac: 'Please enter the device MAC',
        selectTaskStatus: 'Please select the task status',
        snLabel: 'S/N',
        mac: 'MAC',
        model: 'Model',
        bindAccount: 'Bind Account',
        inProgress: 'In progreses',
        expired: 'Expired',
        disabled: 'Disabled',
        disable: 'Disable',
        enable: 'Enable',
        delete: 'Delete',
        inputCodeLabel: 'Device S/N code or MAC',
        deviceInfo: 'Device Information',
        restrictions: 'Restrictions',
        firmConSys: 'Firmware configuration system',
        search: 'Search',
        submit: 'Submit',
        selectArea: 'Select area',
        inputCodeLabelTip: 'Please enter the device S/N code or MAC',
        nextStep: 'Next step',
        back: 'Back',
        jp: 'Asia Pacific',
        eu: 'Europe',
        us: 'America',
        cn: 'China',
        reset: 'Reset',
        operationSuccessful: 'Operation successful',
        deviceNotExist: 'Device does not exist'
    }
}
