// translate router.meta.title, be used in breadcrumb sidebar tagsview
export function generateTitle (title) {
    const hasKey = this.$te('route.' + title)
    const translatedTitle = this.$t('route.' + title) // $t :this method from vue-i18n, inject in @/lang/index.js

    if (hasKey) {
        return translatedTitle
    }
    return title
}

export function translate (target, item) {
    const hasKey = this.$te(`${target}.${item}`)
    const translatedTitle = this.$t(`${target}.${item}`) // $t :this method from vue-i18n, inject in @/lang/index.js

    if (hasKey) {
        return translatedTitle
    }
    return `${target}.${item}`
}
