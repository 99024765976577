/*
 * @Description:
 * @Author: warren
 * @LastEditors: warren
 * @Date: 2023-04-12 14:57:23
 * @LastEditTime: 2023-04-23 16:45:51
 */
import request from '@/api/request'

export function deleteApplication (id) {
    return request({ url: `/v2/app/${id}`, method: 'delete' })
}

/* 获取应用树 */
export function requestApplicationTree () {
    return request({ url: '/v2/app' })
}

/* 创建应用 */
export function requestCreateApplication (data) {
    return request({ url: '/v2/app', method: 'post', data })
}

/* 编辑应用 */
export function requestUpdateApplication (data) {
    return request({ url: '/v2/app', method: 'put', data })
}

/* 获取应用分页列表，树形结构 */
export function requestApplicationTreeList (params) {
    return request({ url: '/v2/app/findByConditions', params })
}
