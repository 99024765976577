/*
 * @Description: 统一对错误进行提示
 * @Author: warren
 * @LastEditors: shufei.han
 * @Date: 2023-10-07 14:26:29
 * @LastEditTime: 2024-07-15 15:28:19
 */
import { Message } from 'element-ui'
import i18n from '@/lang'
import codeList from '@/api/errorCode'

/* 防止一次性显示多条错误信息 */
let preventDuplicate = false
const showErrorMessage = function (response, data = { translate: true }) {
    if (!preventDuplicate) {
        preventDuplicate = true
        const { translate } = data
        Message({ type: 'error', message: translate ? reflectionCode(response) : response })
        setTimeout(function () {
            preventDuplicate = false
        }, 3000)
    }
}
// 针对权限码 -1023 的单独处理
const initSecurityPage = function (response) {
    const securityAuthStatusIsExpired = sessionStorage.getItem('securityAuthStatusIsExpired')
    if (!securityAuthStatusIsExpired || securityAuthStatusIsExpired === 'no') {
        if (!preventDuplicate) {
            preventDuplicate = true
            Message({ type: 'error', message: reflectionCode(response) })
        }
        setTimeout(function () {
            preventDuplicate = false
            sessionStorage.setItem('securityAuthStatusIsExpired', 'yes')
            location.reload()
        }, 3000)
    } else {
        setTimeout(() => {
            sessionStorage.setItem('securityAuthStatusIsExpired', 'no')
        }, 3000)
    }
}
const reflectionCode = function (response) {
    const { code, msg } = response
    if (codeList[code]) {
        return i18n.t(codeList[code])
    } else {
        /* 防止报一长串的后端错误信息 */
        const errString = 'Server Error Message'
        const msgString = typeof msg === 'string' ? msg : errString
        return (msgString.length > 100) ? errString : (msgString || errString)
    }
}

export {
    showErrorMessage,
    initSecurityPage
}
