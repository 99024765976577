/*
 * @Description:
 * @Author: warren
 * @LastEditors: lijiaxin
 * @Date: 2023-04-18 17:51:02
 * @LastEditTime: 2024-03-20 17:19:03
 */
import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
// import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/twofactor'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
    // start progress bar
    NProgress.start()

    // set page title
    // document.title = getPageTitle(to.meta.title)

    // determine whether the user has logged in
    const hasToken = getToken()

    if (hasToken) {
        if (to.path === '/login') {
            // if is logged in, redirect to the home page
            next({ path: '/' })
            NProgress.done()
        } else {
            if (store.getters.permission_resources.length === 0) { // 判断当前用户是否已拉取完permission信息
                try {
                    const response = await store.dispatch('user/GetUserPermissionAPIList')
                    await store.dispatch('user/actionGetUserVar')
                    if (response.code === 0) { // invalid token
                        const permission_array = response.info
                        store.dispatch('permission/GenerateRoutes', { permission_array }).then(roles_rputers => {
                            router.addRoutes(roles_rputers) // 动态添加可访问路由表
                            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                        }).catch(err => {
                            console.log(err)
                        })
                        store.dispatch('permission/SaveResourcePermission', { permission_array }).then(newResources => {
                            // save resource permission
                        })
                    } else {
                        next()
                        // store.dispatch('user/FedLogOut').then(() => {
                        //   // location.reload()
                        // })
                    }
                } catch (err) {
                    store.dispatch('user/FedLogOut').then(() => {
                        Message.error(err || 'Verification failed, please login again')
                        next({ path: '/' })
                    })
                }
            } else {
                next()
            }
            // store.dispatch('user/GetUserPermission').then(response => {
            // })
            // next({ ...to, replace: true })
        }
    } else {
        /* has no token */

        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next()
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            /* next(`/login?redirect=${to.path}`) */
            next('/login')
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
