/**
 * @Author: fuyonghong
 * @Date: 2023-04-10 11:57:50
 * @LastEditTime: 2023-08-30 10:45:55
 * @LastEditors: fuyonghong
 * @Description: var英文翻译
 */
export default {
    var: {
        permanent: 'Permanent',
        chooseOrganization: 'Choose Organization',
        searchPlaceholders: 'Please enter the organization name or address',
        organizationHasAdded: 'The organization has been added',
        editInfo: 'Modify Information',
        editAdmin: 'Edit Administrator',
        orgName: 'Organization Name',
        orgAddress: 'Organization Address',
        totalDevices: 'Total Devices',
        totalMembers: 'Total Members',
        orgAdmin: 'Organization Administrator',
        name: 'Name',
        location: 'Location',
        coordinate: 'Coordinate',
        addOrg: 'Add Organization',
        setAdmin: 'Set Administrator',
        editOrg: 'Modify Organization Information',
        manageAdmin: 'Administrator Management',
        unAccepted: 'Unaccepted',
        unRegistered: 'Unregistered',
        varRoles: 'VAR Roles',
        orgRoles: 'Organization Roles',
        deleteOrg: 'Delete Organization',
        deleteOrgTips: 'Please enter the full name of the current organization to confirm deletion',
        deleteNameError: 'Organization name does not match',
        reinviteOneTips: 'The user will be re-invited to send an email or text message',
        reinviteOneDone: 'The user has been re-invited to send an email or text message',
        repeatInviteError: 'The user is already an administrator of the following organizations and cannot be invited repeatedly',
        region: 'Region',
        europe: 'Europe',
        global: 'Asia Pacific',
        america: 'America',
        cn: 'China',
        createError: 'Children cannot be created under the Var organization'
    }
}
